import { DataService } from "../../../config/dataService/dataService";
import countryData from '../../../demoData/countryCode';
import axios from 'axios';
import { getItem } from '../../../utility/localStorageControl';
const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const state = () => ({
    departments: null,
    position: null,
    crudData: null,
    requestPass: null,
    countryData: countryData,
    userPichure: null

});

const mutations = {
    SET_DEPARTMENTS_DATA(state, data) {
        state.departments = data;
    },
    SET_POSITION_DATA(state, data) {
        state.position = data;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_CRUD_DATA(state, data) {
        state.crudData = data;
    },
    SET_REQUEST_PASS(state, data) {
        state.requestPass = data;
    },
    SET_USER_PICHURE(state, data) {
        state.userPichure = data;
    }
};

const actions = {
    async getDepartments({ commit }) {
        try {
            const data = await DataService.get(`/departments`);
            commit('SET_DEPARTMENTS_DATA', data?.data);
        } catch (error) {
            commit('SET_ERROR', error);
        }
    },
    async getPosition({ commit }) {
        try {
            const data = await DataService.get(`/positions`);
            commit('SET_POSITION_DATA', data?.data);
        } catch (error) {
            commit('SET_ERROR', error);
        }
    },
    async userCrud({ commit }, body) {
        try {
            const endpoint = `/users/information`;
            const data = await DataService.put(endpoint, body);
            commit('SET_CRUD_DATA', data?.status);
        } catch (error) {
            const statusCode = {
                status: error?.response?.status
            }
            commit('SET_ERROR', statusCode);
        }
    },

    async userPassUpdateRequest({ commit }, param) {
        try {
            const endpoint = "/users/password";
            const data = await DataService[param.requestType](endpoint, param.changePassData);
            commit('SET_REQUEST_PASS', data?.status);
        } catch (error) {
            const statusCode = {
                status: error?.response?.status
            }
            commit('SET_REQUEST_PASS', statusCode?.status);
        }
    },

    async userPicture({ commit }, data) {
        try {
            let formData = new FormData();
            formData.append('file', data.file);
            await axios
                .put(API_ENDPOINT + `users/profile`, formData,
                    {
                        headers: { Authorization: `Bearer ` + getItem('token'), 'Content-Type': 'multipart/form-data' }
                    })
                .then((res) => {
                    commit('SET_USER_PICHURE', res.status);
                })
                .catch(() => {
                // 
                });
        } catch (error) {
            commit('SET_USER_PICTURE', error);
        }
    },
};

export default {
    actions,
    mutations,
    state
};
