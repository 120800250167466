import axios from "axios";
import { getItem } from "../../utility/localStorageControl";
import { useRouter } from 'vue-router';
const router = useRouter()

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
const authHeader = () => ({
  Authorization: `Bearer ` + getItem("token"),
  'Accept': 'application/json',
  'Content-Type': 'application/json',
});
const client = axios.create({
  baseURL: API_ENDPOINT,
  // timeout: 5000,
  headers: {
    Authorization: `Bearer ` + getItem("token"),
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
});

class DataService {
  static get(path = "", responseType = "") {
    return client({
      method: "GET",
      url: path,
      responseType: responseType,
      headers: { ...authHeader() },
    });
  }

  static post(path = "", data = {}, optionalHeader = {}) {
    // return new Promise((resolve, reject) => {
    //   const result = client({
    //     method: 'POST',
    //     url: path,
    //     data,
    //     headers: { ...authHeader(), ...optionalHeader },
    //   })
    //   console.log({ result, typeofResult: typeof result })
    //   result.then((...args) => {
    //     console.log({ args })
    //     resolve(...args)
    //   }).catch((...args) => {
    //     console.log({ errorArgs: args })
    //     reject(...args)
    //   })
    // })

    // try {
    //   const result = await client({
    //     method: 'POST',
    //     url: path,
    //     data,
    //     headers: { ...authHeader(), ...optionalHeader },
    //   })
    //   console.log({ result, typeofResult: typeof result })
    //   return result
    // } catch (error) {
    //   console.log({ error })
    // }

    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = "", data = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = "", data = {}) {
    return client({
      method: "DELETE",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = "", data = {}) {
    return client({
      method: "PUT",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ` + getItem("token"),
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        // do something here
      } else if (response.status === 502) {
        router.push('/auth/signin')
      } else if (response.data.code !== undefined || response.data.code !== '') {
        return response.data;
      } else {
        return originalRequest;
      }
    }
    return Promise.reject(error);
  }
);
export { DataService };
