
export default {
    setListCompany(state, listCompany) {
        state.listCompany = listCompany
    },
    setCompanyAccount(state, companyAccount) {
        state.companyAccount = companyAccount
    },
    setCompanyDetail(state, companyDetail) {
        state.companyDetail = companyDetail
    },
    setCompanyDetailStatus(state, data) {
        state.companyDetailStatus = data
    },
    setCompanyOverview(state, data) {
        state.companyOverview = data
    },
    setListBooking(state, data) {
        state.listBooking = data
    },
    setBookingDetail(state, data) {
        state.bookingDetail = data
    },
    setFilterChart(state, filterChart) {
        state.filterChart = filterChart
    },
    setCompanyActivity(state, data) {
        state.companyActivity = data
    },
    setStaffList(state, data) {
        state.staffList = data
    },
    setStaffDetail(state, data) {
        state.staffDetail = data
    },
    setStaffBookingList(state, data) {
        state.staffBookingList = data
    },
    setStaffActivity(state, data) {
        state.staffActivity = data
    },
}