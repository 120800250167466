
export default {
    setDestinations(state, destinations) {
        state.destinations = destinations
    },
    setSuccessStatus(state, data) {
        state.successStatus = data
    },
    setCountry(state, country) {
        state.country = country
    },
    setCity(state, city) {
        state.city = city
    },
    setAirport(state, airport) {
        state.airport = airport
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setDestinationsError(state, error) {
        state.error = error
    },
    setUpdateCountryId(createState, countryId) {
        createState.countryId = countryId;
    },
    setUpdateCityId(createState, cityId) {
    createState.cityId = cityId;
    },
    setUpdateAirportId(createState, airportId) {
    createState.airportId = airportId;
    },
    setUpdateStatus(createState, status) {
    createState.status = status;
    },
    setHotelDestinations(state, destinations) {
        state.destinationsHotel = destinations
    },
    setHotelGroupName(state, groupName) {
        state.groupName = groupName
    },
    setHotelCountry(state, country) {
        state.hotelCountry = country
    },
    setHotelCity(state, city) {
        state.hotelCity = city
    },
}