import mutations from './mutations';

const state = () => ({
  lang : '',
  loading: false,
  error: null,
});

const actions = {
   getLang({ commit }, lang) {
    try {     
      commit('setLang',lang );
    } catch (err) {
      commit('getErr', err);
    }
  }
};

const getters ={
  lang : state => state.lang
}
export default {
  namespaced: false,
  state,
  actions,
  getters,
  mutations,
};
