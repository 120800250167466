export default {
  setRoles(state, roles) {
      state.roles = roles
  },
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
  setRoleDetail(state, detail) {
    state.detail = detail
  },
  setSuccessStatus(state, data) {
      state.successStatus = data
  },
  setFailedStatus(state, data) {
    state.failedStatus = data
  },
  setUpdateRolePermissionStatus(state, data) {
    state.updateRolePermissionStatus = data
  },
  setLoading(state, loading) {
      state.loading = loading
  },
  setUpdateStatus(createState, status) {
    createState.status = status;
  }
}