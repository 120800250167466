import mutations from "./mutations";
import Cookies from "js-cookie";
import { DataService } from "../../../config/dataService/dataService";

const state = () => ({
  customters: [],
  passengers: [],
  login: Cookies.get("logedIn"),
  loading: false,
  error: null,
});

const actions = {
  async getAllCustomers(
    { commit },
    { pageSize, pageNum, keywords, customFilter, countryQuery, userFromFilter }
  ) {
    console.log(countryQuery);
    const country = countryQuery.value ? "&country=" + countryQuery.value : "";
    const userType =
      userFromFilter.value !== "" ? "&userFrom=" + userFromFilter.value : "";
    console.log("userType", keywords);
    try {
      commit("customerBegin");
      const query = await DataService.get(
        `customers?customerType=${customFilter.value}${userType}&search=${keywords}${country}&pageSize=${pageSize}&pageNum=${pageNum}
        `
      );
      commit("customerList", query?.data);
    } catch (err) {
      commit("getErr", err);
    }
  },
  async getPassengerByCID({ commit }, { customerid }) {
    try {
      commit("passengerBegin");
      const query = await DataService.get(
        `customers/passenger${customerid ? `/${customerid}` : ""}`
      );
      commit("passengerList", query?.data);
    } catch (err) {
      commit("getErr", err);
    }
  },
  async updateCustomerType({ commit }, { custmerID, type }) {
    try {
      await DataService.put(`customers/type`, {
        id: custmerID,
        type: type, // VIP, NORMAL
      });
    } catch (err) {
      commit("getErr", err);
    }
  },
  async getCountriesCustomer({ commit }) {
    try {
      commit("countriesBegin");
      const query = await DataService.get(`destinations/get-countries`);
      commit("countriesList", query?.data);
    } catch (err) {
      commit("getErr", err);
    }
  },
  async updateCustomerPhone({ commit }, { id, phoneNumber, countryCode }) {
    try {
      const query = await DataService.put(`customers/phone`, {
        id: id,
        value: phoneNumber,
        code: countryCode
      });
      return query
    } catch (err) {
      commit("getErr", err);
    }
  },
  async updateCustomerEmail({ commit }, { id, email }) {
    try {
      const query = await DataService.put(`customers/email`, {
        id: id,
        value: email
      });
      return query
    } catch (err) {
      commit("getErr", err);
    }
  }
};

//getter
const getters = {
  passengers: (state) => state.passenger,
  loader: (state) => state.loading,
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
