import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';
import store from '@/vuex/store';
import Cookies from 'js-cookie';

// const ACCOUNT_URL = process.env.VUE_APP_SKYBOOKING_BO_URL;
// const BASE_URL = process.env.VUE_APP_DOMAIN_URL;

const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
    meta: { auth: false },
  },
  {
    name: 'Auth',
    path: '/auth',
    // component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: { auth: true },
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

// router.beforeEach((to, from, next) => {
  
//   if (to.meta.auth && store.state.auth.login) {
//     next({ to: '/' });
//   } else if (!to.meta.auth && !store.state.auth.login) {
//     if (to.query?.token) {
//       localStorage.setItem('token', to.query?.token)
//       localStorage.setItem('loginStatus', 'true')
//       localStorage.setItem('tokenExpire', new Date().getTime() + 1 * 86400000);
//       Cookies.set('logedIn', true);
//       next({ to: '/' });
//     } else {
//       if (localStorage.getItem('token') === null) {
//         window.location.href = ACCOUNT_URL + `?redirect=${BASE_URL}`
//       } else {
//         // if (localStorage && localStorage.token) {
//         //     var now = new Date();
//         //     if (localStorage && localStorage.tokenExpire) {
//         //         if (now.getTime() > localStorage.tokenExpire) {
//         //           window.location.href = ACCOUNT_URL + `?redirect=${BASE_URL}`
//         //         } else {
//         //           window.location.href = BASE_URL
//         //         }
//         //     } else {
//         //       window.location.href = ACCOUNT_URL + `?redirect=${BASE_URL}`
//         //     }
//         // }
//         // next({ name: 'login' });
//         window.location.href = BASE_URL
//       }
//     }
//   } else {
//     next();
//   }
//   window.scrollTo(0,0);
// });

router.beforeEach((to, from, next) => {

  if (to.meta.auth && store.state.auth.login) {
    next({ to: '/' });
  } else if (!to.meta.auth && !store.state.auth.login) {
    if (to.query?.token) {
      localStorage.setItem('token', to.query?.token)
      localStorage.setItem('loginStatus', 'true')
      Cookies.set('logedIn', true);
      next({ to: '/' });
    } else {
      next({ name: 'login' });
    }
  } else {
    next();
  }

  window.scrollTo(0,0);
});
export default router;
