// import FeaturesRoutes from './FeaturesRoutes';
import PageRoutes from "./PageRoutes";
import Components from "./Components";
import Apps from "./Applications";
// import store from '@/vuex/store';

const routes = [
  {
    path: "",
    name: "dashboard",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/Dashboard.vue"
      ),
    children: [
      {
        path: "",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/view/dashboard"),
      },
      {
        path: "customer",
        name: "customer",
        component: () =>
          import(
            /* webpackChunkName: "Business" */ "@/view/customerManagements"
          ),
      },
      {
        path: "pnr-cancellation",
        name: "PNRCancellation",
        component: () =>
          import(/* webpackChunkName: "Business" */ "@/view/PNRCancellation"),
      },
      {
        path: "popular-city",
        name: "popularCity",
        component: () =>
          import(/* webpackChunkName: "Business" */ "@/view/popularCity"),
      }
    ],
  },
  // Content Management
  {
    path: "/content-management",
    name: "contentManagement",
    component: () => import("@/view/contentManagement/index.vue"),
    children: [
      {
        path: "airline",
        name: "airline",
        component: () => import("@/view/contentManagement/airline/index.vue"),
      },
      {
        path: "airport",
        name: "airport",
        component: () => import("@/view/contentManagement/airport/index.vue"),
      },
      {
        path: "aircraft",
        name: "aircraft",
        component: () => import("@/view/contentManagement/aircraft/index.vue"),
      },
      {
        path: "region",
        name: "region",
        component: () => import("@/view/contentManagement/region/index.vue"),
      },
    ],
  },
  // top-destination
  {
    path: "/top-destination",
    name: "top-destination",
    component: () =>
      import(
        /* webpackChunkName: "top-destination" */ "@/view/top-destination/Index"
      ),
  },
  {
    path: "currency",
    name: "currency",
    component: () => import("@/view/currency"),
  },
  {
    path: "/profit-setting",
    name: "profit-setting",
    component: () =>
      import(
        /* webpackChunkName: "top-destination" */ "@/view/profit-setting/index"
      ),
  },
  {
    path: "/cancellation-fee",
    name: "cancellation-fee",
    component: () =>
      import(
        /* webpackChunkName: "top-destination" */ "@/view/cancellation-fee/Index"
      ),
  },
  {
    path: "/companies",
    name: "companies",
    component: () =>
      import(
        /* webpackChunkName: "company" */ "@/view/companyManagement/index"
      ),
    children: [
      {
        path: "/companies/view",
        name: "company",
        component: () =>
          import(
            /* webpackChunkName: "company" */ "@/view/companyManagement/View.vue"
          ),
      },
      {
        path: "/companies/company-detail/:id",
        name: "company-detail",
        component: () =>
          import(
            /* webpackChunkName: "company-detail" */ "@/view/companyManagement/companyDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/order",
    name: "order",
    component: () =>
      import(/* webpackChunkName: "top-destination" */ "@/view/orders/index"),
    children: [
      {
        path: "/order/flight-detail/:id",
        name: "flight-detail",
        component: () =>
          import(
            /* webpackChunkName: "company-detail" */ "@/view/orders/Flight/detail.vue"
          ),
      },
      {
        path: "/order/view/",
        name: "order-view",
        component: () =>
          import(
            /* webpackChunkName: "company-detail" */ "@/view/orders/View.vue"
          ),
      },
    ],
  },
  {
    path: "/payment-method-fee",
    name: "payment-method-fee",
    component: () =>
      import(
        /* webpackChunkName: "payment-method-fee" */ "@/view/payment-method-fee/index"
      ),
  },
  {
    path: "/user-management",
    name: "user-management",
    component: () =>
      import(
        /* webpackChunkName: "user-management" */ "@/view/user-management/index"
      ),
  },
  {
    path: '/role-permission',
    name: 'role-permission',
    component: () => import(/* webpackChunkName: "top-destination" */ '@/view/rolePermission/index'),
    children: [
      {
        path: '/role-permission/role-detail/:id',
        name: 'detail-role',
        component: () => import(/* webpackChunkName: "company" */ '@/view/rolePermission/roleDetail.vue'),
      },
      {
        path: '/role-permission/view',
        name: 'permission',
        component: () => import(/* webpackChunkName: "company" */ '@/view/rolePermission/view.vue'),
      }
    ]
  },
  {
    path: '/user-profile',
    name: 'userProfile',
    component: () => import(/* webpackChunkName: "payment-method-fee" */'@/view/userProfile/index'),
  },
  {
    path: '/skypoint',
    name: 'skyPoint',
    component: () => import(/* webpackChunkName: "skyPoint" */'@/view/skyPoint/index'),
  },
  ...PageRoutes,
  // ...FeaturesRoutes,
  ...Components,
  ...Apps,
];

export default routes;
