import { DataService } from '../../../config/dataService/dataService';
import mutations from './mutations';
const state = () => ({
    cancellationFees: {},
    cancellationHotelFees: {},
    loading: false,
    error: null,
    });
   
const actions = {
    async getCancellationFees({ commit }, {page, size}) {       
        try {
            const response = await DataService.get(`cancellation-fees?pageSize=${size}&pageNum=${page}`);
            commit('setCancellationFee', response.data)
          
          } catch (err) {
            commit('loginErr', err);
          }
        
    },

    async createPaymentMethodFee(_, data) {  
      return await DataService.post(`cancellation-fees`, data);
    },

    // async createPaymentMethodFee({ commit }, data) {       
    //     try {
    //        const response = await DataService.post(`cancellation-fees`, data);
    //        console.log({ response })
    //        console.log({ data: response.data })
    //       //  DataService.post(`cancellation-fees`, data).then((...args) => console.log({ args }))
    //         // if(response.status !== 200) {
    //         //   // getters.error()
    //         //   console.log({ response })
    //         //   commit('setCreateError');
    //         // }
    //         // commit('requestCreate', response.status )
    //         // commit('setCancellationFee', response.data);
          
    //     } catch (err) {
    //       console.log({ err })
    //       commit('setCreateError', err);
    //     }
        
    // },
    async updatePaymentMethodFee(_, data) {       
           return await DataService.put(`cancellation-fees`, data);
    },

    async deletePaymentMethodFee({ commit }, {id}) {    
        try {
            const response = await DataService.delete(`cancellation-fees/${id}`);
            console.log(response)
            // commit('setCancellationFee', response.data)
          
          } catch (err) {
            commit('loginErr', err);
          }
        
    },
    async getHotelCancellationFees({ commit }, {page, size}) {       
        try {
            const response = await DataService.get(`hotel_cancellation_fee?pageSize=${size}&pageNum=${page}`);
            commit('setHotelCancellationFee', response.data)
          
          } catch (err) {
            commit('loginErr', err);
          }
    },
    async updateHotelCancellationFee({ commit }, data) { 
        try {
          const response = await DataService.put(`hotel_cancellation_fee`, data);
          return response
        } catch(err) {
          commit('loginErr', err);
        }
        // return await DataService.put(`hotel_cancellation_fee`, data);
    },
    async createHotelCancellationFee({ commit }, data) {
      try {
        const response = await DataService.post(`hotel_cancellation_fee`, data);
        return response
      } catch(err) {
        commit('loginErr', err);
      }
      // return await DataService.post(`hotel_cancellation_fee`, data);
    },
    async deleteHotelCancellationFee({ commit }, {id}) {    
      try {
          const response = await DataService.delete(`hotel_cancellation_fee/${id}`);
          console.log(response)
          // commit('setCancellationFee', response.data)
        
        } catch (err) {
          commit('loginErr', err);
        }
      
  },
}

const getters = {
    cancellationFee: state => state.cancellationFees,
    error: state => state.error,
    cancellationHotelFee: state => state.cancellationHotelFees
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};