import { DataService } from "../../../config/dataService/dataService";
import { getItem } from "../../../utility/localStorageControl";
import axios from "axios";
const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
const lang = localStorage.getItem('lang')
const state = () => ({
  loading: false,
  flightData: [],
  hotelData: [],
  airline: [],
  formFilter: {},
  error: null,
  detail: null,
  countries: [],
  city: [],
  detail_cancellation_flight: [],
  detail_activity_flight: []
});

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_FLIGHT_DATA(state, data) {
    state.flightData = data;
  },
  SET_DETAIL_DATA(state, data) {
    state.detail = data;
  },
  SET_HOTEL_DATA(state, data) {
    state.hotelData = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  setAirline(state, data) {
    state.airline = data;
  },
  SET_FORMFILTER(state, data) {
    state.formFilter = data;
  },
  SET_COUNTRY(state, data) {
    state.countries = data;
  },
  SET_CITY(state, data) {
    state.city = data;
  },
  SET_FLIGHT_CANCELLATION__DATA(state, data) {
    state.detail_cancellation_flight = data;
  },
  SET_DETAIL_ACTIVITY_DATA(state, data) {
    state.detail_activity_flight = data
  }
};

const actions = {
  // Flight Oders
  async getFlightOrders(
    { commit },
    {
      pageNum,
      pageSize,
      start,
      end,
      search,
      tripTypes,
      paymentMethods,
      status,
      stop,
      transit,
      passengerNum,
      airlineCodes,
      isChecked,
      bookingBy,
      depCityId,
      depAirport,
      arrCityId,
      arrAirport,
      classify,
      paymentStatus,
    }
  ) {
    try {
      commit("SET_LOADING", true);
      const data = await DataService.get(
        `bookings?pageNum=${pageNum}&pageSize=${pageSize}&startDate=${start}&endDate=${end}&search=${search}&startAmount=0.00&endAmount=10000.00&tripTypes=${tripTypes}&paymentMethods=${paymentMethods}&status=${status}&stop=${stop}&transit=${transit}&passengerNum=${passengerNum}&airlineCodes=${airlineCodes}&isChecked=${isChecked}&userType=${bookingBy}&departureCityId=${depCityId}&departureAirport=${depAirport}&arriveCityId=${arrCityId}&arriveAirport=${arrAirport}&classify=${classify}&paymentStatus=${paymentStatus}`
      );

      commit("SET_FLIGHT_DATA", data?.data);

      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_ERROR", error);
      commit("SET_LOADING", false);
    }
  },

  async getActivity({commit}, bookingID) {
    try {
        commit("SET_LOADING", true);
        const response = await DataService.get(`bookings/activity?bookingId=${bookingID}`);

        return response?.data
      } catch (err) {
        commit('loginErr', err);
      }
  },

  async downloadPDF({ commit }, data) {
    try {
      const response =  await axios
      .get(API_ENDPOINT + data?.param, {
        headers: {
          Authorization: `Bearer ` + getItem('token'), 'Accept': 'application/pdf'
        },
        responseType: 'blob' })
        if(response?.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const objectUrl = window.URL.createObjectURL(blob)
          const link = document.createElement("a");
          link.href = objectUrl
          link.download = data?.type+`_${new Date().getTime()}.pdf`;
          link.click();
          return response
        } else {
          return response
        }
      } catch (err) {
        commit('loginErr', err);
      }
  },

  async sendFile({ commit }, param) {
    try {
      const response = await DataService.post(`/bookings/send-file`, param);
      return response.data;
    } catch (err) {
      commit("loginErr", err);
    }
  },

  async bankSettlement({ commit }, param) {
    try {
      const response = await DataService.put(
        `/bookings/bank-settlement`,
        param
      );
      return response.data;
    } catch (err) {
      commit("loginErr", err);
    }
  },

  async getCancellationPolicy({ commit }, bookingID) {
    try {
      commit("SET_LOADING", true);
      const response = await DataService.get(
        `/bookings/cancellation-fee/${bookingID}`
      );
      commit("SET_FLIGHT_CANCELLATION__DATA", response?.data);
      return response.data;
    } catch (err) {
      commit("loginErr", err);
    }
  },

  async getCancellationDetail({ commit }, { bookingCode, cancellationCode }) {
    try {
      const response = await DataService.get(
        `/bookings/cancellation-detail?bookingCode=${bookingCode}&cancellationCode=${cancellationCode}`
      );
      return response?.data;
    } catch (err) {
      commit("loginErr", err);
    }
  },

  async getAirlines({ commit }) {
    try {
      const response = await DataService.get(
        `/airlines?pageSize=&pageNum=&search=`
      );
      commit("setAirline", response.data);
    } catch (err) {
      commit("loginErr", err);
    }
  },

  async getOrderDetail({ commit }, param) {
    try {
      commit("SET_LOADING", true);
      const data = await DataService.get(`/bookings/${param}`);
      commit("SET_DETAIL_DATA", data?.data);
      return data?.data;
    } catch (error) {
      //commit('setError', error);
    }
  },

  async updatePayment({ commit }, data) {
    try {
      let formData = new FormData();
      formData.set("bankName", data?.bankName);
      formData.set("accountName", data?.accountName);
      formData.set("accountNumber", data?.accountNumber);
      formData.set("receivedDate", data?.receivedDate);
      formData.set("file", data?.file);
      return await axios
        .put(API_ENDPOINT + `bookings/${data?.bookingId}/payments`, formData, {
          headers: {
            Authorization: `Bearer ` + getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("updatePayment Status", res.status);
        })
        .catch((err) => {
          console.log("updatePayment err  Status", err.status);
          console.log(err);
        });
    } catch (error) {
      // Handle errors by committing them to the Vuex store
      commit("setError", error);
    }
  },

  async getBookingActivity({ commit }, param) {
    const rangeStart =  param?.start !== undefined && param?.start !== '' ? param?.start : ''
    const rangeEnd =  param?.end !== undefined && param?.end !== '' ? param?.end : ''
    const activi =  param?.selectActivity !== undefined && param?.selectActivity !== '' ? param?.selectActivity : ''
    try {
      commit("SET_LOADING", true);
      const data = await DataService.get(`/hotel_booking/detail/${param?.bookingId}/activity-log?startDate=${rangeStart}&endDate=${rangeEnd}&activityLogType=${activi}`);
      commit("SET_DETAIL_ACTIVITY_DATA", data?.data);
      return data?.data;
    } catch (error) {
      //commit('setError', error);
    }
  },

  // async exportExcel({commit}) {
  //   try {
  //       const response = await DataService.get(`/bookings/export`);
  //       var blob = new Blob([response.data], {
  //         type: response.headers["content-type"],
  //       });
  //       const link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob);
  //       link.download = `order_${new Date().getTime()}.xlsx`;
  //       link.click();
  //       return response?.data
  //     } catch (err) {
  //       commit('loginErr', err);
  //     }
  // },

  // Hotel Orders
  async getHotelOrders({ commit },{pageNum, pageSize, search,checkin,checkout, route}) {

    try {
      commit("SET_LOADING", true);
      const data = await DataService.get(
        `/hotel_booking/list?lang=${lang}&pageNum=${pageNum}&pageSize=${pageSize}&checkin=${checkin}&checkout=${checkout}&search=${search}&countryCode=${route.query.countryCode || ''}&cityCode=${route.query.cityCode || ''}&minPrice=${route.query.minPrice || ''}&maxPrice=${route.query.maxPrice || ''}&hotelStar=${route.query.hotelStar || ''}&paymentStatus=${route.query.paymentStatus || ''}&orderStatus=${route.query.orderStatus || ''}&paymentMethod=${route.query.paymentMethod || ''}`
      );
      commit("SET_HOTEL_DATA", data?.data);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_ERROR", error);
      commit("SET_LOADING", false);
    }
  },

  async getHotelDetail({ commit }, param) {
    try {
      commit("SET_LOADING", true);
      const data = await DataService.get(`/hotel_booking/detail/${param}?lang=${lang}`);
      commit("SET_DETAIL_DATA", data?.data);
      return data?.data;
    } catch (error) {
      //commit('setError', error);
    }
  },

  async fetchCancellationDetail({ commit }, param) {
    try {
      commit("SET_LOADING", true);
      const data = await DataService.get(`hotel_booking/cancellation/${param}?lang=${lang}`);
      //commit("SET_DETAIL_DATA", data?.data);
      return data?.data;
    } catch (error) {
      //commit('setError', error);
    }
  },

  async updateHotelPayment({ commit }, data) {
    try {
      let formData = new FormData();
      formData.set("bankName", data?.bankName);
      formData.set("accountName", data?.accountName);
      formData.set("accountNumber", data?.accountNumber);
      formData.set("receivedDate", data?.receivedDate);
      formData.set("totalAmount", data?.totalAmount);
      formData.set("file", data?.file);
      const response = await axios
        .put(API_ENDPOINT + `hotel_booking/detail/vip-payment/${data?.bookingId}`, formData, {
          headers: {
            Authorization: `Bearer ` + getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        })
        return response
    } catch (error) {
      // Handle errors by committing them to the Vuex store
      commit("setError", error);
    }
  },

  async confirmCancellation({ commit }, {data,bookingId}) {
    try {
      return await axios
        .post(API_ENDPOINT + `hotel_booking/cancellation/${bookingId}`, data, {
          headers: {
            Authorization: `Bearer ` + getItem("token")
          },
        })
        .then((res) => {
          console.log("Cancellation Status", res.status);
          return res;
        })
        .catch((err) => {
          //console.log("Cancellation err  Status", err.status);
          console.log(err);
        });
    } catch (error) {
      // Handle errors by committing them to the Vuex store
      commit("setError", error);
    }
  },
 
  async orderRefundSettlement({ commit }, {param,bookingId}) {
    try {
      const response = await DataService.post(
        `hotel_booking/refund/${bookingId}`,
        param
      );
      return response.data;
    } catch (err) {
      commit("loginErr", err);
    }
  },

  async sendOrderFiles({ commit }, param) {
    try {
      const response = await DataService.post(`/hotel_booking/send-file`, param);
      return response;
    } catch (err) {
      commit("loginErr", err);
    }
  },

  async orderDownloadPDF({ commit }, {data}) {
    try {
      const res = await DataService.get(API_ENDPOINT + `hotel_booking/download/${data?.bookingCode}?type=${data?.type}&lang=${data?.lang}`, {
        headers: {
          Authorization: `Bearer ` + getItem('token')
        }})
        if(res.status === 200){
          fetch(res.data.url)
            .then(response => response.blob())
            .then(blob => {
              let url = window.URL.createObjectURL(blob);
              let link = document.createElement('a');
              link.href = url;
              link.download = data?.fileName+`_${new Date().getTime()}.pdf`; // or any other filename you want
              link.click();
            })
            .catch(console.error);
            return res
        }else {
          return res
        }


        // if(response?.status === 200) {
        //   const blob = new Blob([response.data.url], { type: 'application/pdf' })
        //   const objectUrl = window.URL.createObjectURL(blob)
        //   const link = document.createElement("a");
        //   link.href = objectUrl
        //   link.download = data?.type+`_${new Date().getTime()}.pdf`;
        //   link.click();
        //   return response
        // } else {
        //   return response
        // }
      } catch (err) {
        commit('loginErr', err);
      }
  },
  
  postFormFilter({ commit }, param) {
    commit("SET_LOADING", true);
    commit("SET_FORMFILTER", param);
    commit("SET_LOADING", false);
  },

  // Get list country
  async getCountriesHotel({ commit }) {
    try {
      const data = await DataService.get('/hotel_city_country/country');
      commit("SET_COUNTRY", data?.data);
    } catch (error) {
      // 
    }
  },
  async getCityHotel({ commit }, param) {
    try {
      const data = await DataService.get(`/hotel_city_country/city?countryCode=${param}`);
      commit("SET_CITY", data?.data);
    } catch (error) {
      // 
    }
  },
  async TicketRefundSettlement({ commit }, param) {
    try {
      const response = await DataService.put(
        `bookings/fail-refund`,
        param
      );
      return response.data;
    } catch (err) {
      commit("loginErr", err);
    }
  },
};

const getters = {
  loading: (state) => state.loading,
  airline: (state) => state.data,
  detail: (state) => state.detail,
  getFormFilter: (state) => state.formFilter,
};
export default {
  actions,
  mutations,
  state,
  getters,
};
