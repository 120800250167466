<template>
  <CustomDropDown id="dropdownParentPoint">
    <div
      v-if="isVisible"
      :class="`sDash-range-calendar ${
        props.hasLeftMenu ? 'has-left-menu' : ''
      } horizontal-placement-${props.dropdownPlacement}`"
      placement="bottomRight"
      title="Drag to select range of dates"
      action="click"
    >
      <VDatePicker
        is-expanded
        v-model.range="range"
        :is-range="true"
        columns="2"
        :max-date="new Date()"
        :locale="language"
      >
        <template #footer>
          <div class="custom-footer">
            <div v-if="props.hasLeftMenu" class="list-date-select">
              <a-menu
                id="calender-quick-select-menu-point"
                v-model:selectedKeys="selectedQuickMenuKeys"
                mode="inline"
                @click="handleQuickMenuClick"
              >
                <a-menu-item v-for="item in quickMenuOptions" :key="item.key">
                  {{ $t(item.labelTKey) }}
                </a-menu-item>
              </a-menu>
            </div>
            <div class="footer-datepicker">
              <p class="m-0" v-if="range?.startDate">
                {{ formatDateTime(range?.startDate, "MMM DD, YYYY") }} -
                {{ formatDateTime(range?.endDate, "MMM DD, YYYY") }}
              </p>
              <!-- <a-button type="primary">Apply</a-button> -->
              <a-button @click="onClose('cancel')">{{
                $t("dashboards.Cancel")
              }}</a-button>
            </div>
          </div>
        </template>
      </VDatePicker>
    </div>
    <div class="btn-group">
      <a-radio-group
        v-if="props.isButton === 'on'"
        @change="handleBtnGroupChange"
        v-model:value="selectedValue"
      >
        <a-radio-button
          v-for="item in btnGroupOptions"
          :key="item.key"
          :value="item.key"
          @click="onClose"
        >
          {{ $t(item.labelTKey) }}
        </a-radio-button>
      </a-radio-group>

      <sdButton
        @click="handleVisiblePoint"
        size="small"
        type="white"
        style="margin-left: 5px"
      >
        <div
          style="
            display: flex;
            justify-items: start;
            width: 100%;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="display: flex; align-items: center">
            <sdFeatherIcons
              v-if="isIcon === 'on'"
              class="text-primary"
              type="calendar"
              size="14"
            />
            <span>{{
              $route.query[queryKeys.startDate] && $route.query[queryKeys.endDate]
                ? titles
                : $t(`dashboards.${title}`)
            }}</span>
            <sdFeatherIcons v-if="isChevron" type="chevron-down" size="14" />
          </div>
          <div>
            <sdFeatherIcons
              v-if="isIconRigth === 'on'"
              type="calendar"
              size="14"
            />
          </div>
        </div>
      </sdButton>
    </div>
  </CustomDropDown>
</template>

<script>
  import dayjs from "dayjs";
  import { onMounted, ref, defineComponent, watch } from "vue";
  import { useStore } from "vuex";
  import { CustomDropDown } from "./styled";
  import { formatDateTime } from "@/components/utilities/skybookingBo";
  import { computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  export default defineComponent({
    name: "CalendarButtonPoint",
    props: {
      isButton: {
        type: String,
        required: true,
      },
      isButtonCompany: {
        type: String,
        required: true,
      },
      isRegion: {
        type: Boolean,
        required: true,
      },
      hasLeftMenu: {
        type: Boolean,
        required: false,
      },
      title: {
        type: String,
        required: false,
      },
      isIcon: {
        type: String,
        required: true,
      },
      marginRight: {
        type: String,
        required: false,
      },
      isChevron: {
        type: Boolean,
        required: true,
      },
      isIconRigth: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        required: false,
      },
      queryPrefix: {
        type: String,
        required: false,
      },
      dropdownPlacement: {
        type: String,
        required: false,
        default: "right",
        validator: (value) => ["left", "right"].includes(value),
      },

      keepOpenOnChange: Boolean,
    },
    components: {
      CustomDropDown,
    },
    setup(props) {
      const { dispatch } = useStore();
      const route = useRoute();
      const router = useRouter();
      const language = ref(localStorage.getItem("lang") || "en");
      // console.log('state', state);
      const isVisible = ref(false);
      const handleVisiblePoint = () => {
        isVisible.value = !isVisible.value;
      };

      const RANGES = {
        today: { startDate: dayjs().toDate(), endDate: dayjs().toDate() },
        yesterday: {
          startDate: dayjs().add(-1, "d").toDate(),
          endDate: dayjs().toDate(),
        },
        last7day: {
          startDate: dayjs().add(-7, "d").toDate(),
          endDate: dayjs().toDate(),
        },
        last30day: {
          startDate: dayjs().add(-30, "d").toDate(),
          endDate: dayjs().toDate(),
        },
        thisMonth: {
          startDate: dayjs().startOf("month").toDate(),
          endDate: dayjs().toDate(),
        },
        lastMonth: {
          startDate: dayjs().subtract(1, "month").startOf("month").toDate(),
          endDate: dayjs().subtract(1, "month").endOf("month").toDate(),
        },
        thisQuarter: {
          startDate: dayjs().subtract(2, "month").toDate(),
          endDate: dayjs().startOf("month").toDate(),
        },
        thisYear: {
          startDate: dayjs().add(-365, "d").toDate(),
          endDate: dayjs().toDate(),
        },
      };
      const selectedValue = ref("today");

      const btnGroupOptions = [
        { key: "today", labelTKey: "dashboards.Today", range: RANGES.today },
        { key: "week", labelTKey: "dashboards.week", range: RANGES.last7day },
        {
          key: "month",
          labelTKey: "dashboards.month",
          range: RANGES.thisMonth,
        },
        { key: "year", labelTKey: "dashboards.year", range: RANGES.thisYear },
      ];
      const handleBtnGroupChange = (event) => {
        const foundItem = btnGroupOptions.find(
          (item) => item.key === event.target.value
        );
        if (foundItem?.range) setRange(foundItem.range);
      };

      const quickMenuOptions = [
        { key: "today", labelTKey: "dashboards.Today", range: RANGES.today },
        {
          key: "yesterday",
          labelTKey: "dashboards.Yesterday",
          range: RANGES.yesterday,
        },
        {
          key: "last7day",
          labelTKey: "dashboards.Last7day",
          range: RANGES.last7day,
        },
        {
          key: "last30day",
          labelTKey: "dashboards.Last30day",
          range: RANGES.last30day,
        },
        {
          key: "thisMonth",
          labelTKey: "dashboards.ThisMonth",
          range: RANGES.thisMonth,
        },
        {
          key: "lastMonth",
          labelTKey: "dashboards.LastMonth",
          range: RANGES.lastMonth,
        },
        {
          key: "thisYear",
          labelTKey: "dashboards.year",
          range: RANGES.thisYear,
        }
      ];
      const selectedQuickMenuKeys = ref([]);
      const handleQuickMenuClick = (event) => {
        const foundItem = quickMenuOptions.find(
          (item) => item.key === event.key
        );
        if (foundItem?.range) setRange(foundItem.range);
      };
      // const setSelectedQuickMenuKeysByRange = (range) => {
      //   console.log('setSelectedQuickMenuKeysByRange ', range)
      //   const foundItem = quickMenuOptions.find(
      //     (item) =>
      //       dayjs(item.range.startDate).isSame(range.startDate, "day") &&
      //       dayjs(item.range.endDate).isSame(range.endDate, "day")
      //   );
      //   if (foundItem?.key) {
      //     if (selectedQuickMenuKeys.value[0] !== foundItem?.key)
      //       selectedQuickMenuKeys.value = [foundItem.key];
      //   } else {
      //     selectedQuickMenuKeys.value = [];
      //   }
      // };

      const setSelectedQuickMenuKeysByRange = (range) => {
        const foundItem = quickMenuOptions.find(
          (item) =>
            dayjs(item.range.startDate).isSame(range.startDate, "day") &&
            dayjs(item.range.endDate).isSame(range.endDate, "day")
        );
        if (foundItem?.key) {
          selectedValue.value = foundItem?.key === 'last7day' ? 'week' : foundItem?.key === 'thisMonth' ? 
          'month' : foundItem?.key === 'thisYear' ? 'year' : 'today';
          if (selectedQuickMenuKeys.value[0] !== foundItem?.key)
            selectedQuickMenuKeys.value = [foundItem.key];
        } else {
          selectedQuickMenuKeys.value = [];
        }
      };

      const queryKeys = computed(() => {
        return !props.queryPrefix
          ? { startDate: "startDate", endDate: "endDate", chartStatus: "chartStatus" }
          : {
              startDate: `${props.queryPrefix}-startDate`,
              endDate: `${props.queryPrefix}-endDate`,
              chartStatus: `${props.queryPrefix}-chartStatus`,
            };
      });
      const handleResetRoute = () => {
        let newQuery = Object.keys(route.query)
          .filter((key) => key !== "startDate" && key !== "endDate")
          .reduce((result, key) => {
            result[key] = route.query[key];
            return result;
          }, {});

        router.replace({ query: newQuery });
      };
      const onClose = (value) => {
        isVisible.value = false;
        if (value === "cancel") {
          range.value = { startDate: "", endDate: "" };
          let newQuery = Object.keys(route.query)
            .filter((key) => !Object.values(queryKeys.value).includes(key))
            .reduce((result, key) => {
              result[key] = route.query[key];
              return result;
            }, {});

          router.replace({ query: newQuery });

          handleResetRoute();
          selectedValue.value = "today";
        }
      };
      const value = ref("today");

      const btnCompanyOptions = [
        {
          key: "present",
          labelTKey: "companyManagement.overview.present",
          range: RANGES.thisMonth,
        },
        {
          key: "quarter",
          labelTKey: "companyManagement.overview.quarter",
          range: RANGES.thisQuarter,
        },
        {
          key: "year",
          labelTKey: "companyManagement.overview.year",
          range: RANGES.thisYear,
        },
      ];

      const range = ref({
        startDate: "",
        endDate: "",
      });

      const setRange = ({ startDate, endDate }) => {
        range.value = { startDate, endDate };
      };

      const getCalendars = async () => {
        await dispatch("getCalendars", { range });
        router.replace({
          query: {
            ...route.query,
            [queryKeys.value?.startDate]: range.value?.start ? range.value?.start : range.value?.startDate,
            [queryKeys.value?.endDate]: range.value?.end ? range.value?.end : range.value?.endDate,
          },
        });
      };

      const marginRight = ref(props.marginRight || "unset");
      const width = ref(props.width || "100%");

      onMounted(() => {
        document.body.addEventListener("click", (e) => {
          if (e.target.closest("#dropdownParentPoint")) {
            return false;
          } else {
            isVisible.value = false;
          }
        });
        // get style props
        document.documentElement.style.setProperty(
          "--margin-right",
          marginRight.value
        );
        document.documentElement.style.setProperty("--width", width.value);

        // Calculate tomorrow's date
      });

      const titles = computed(
        () =>
          ` ${formatDateTime(
            route.query[queryKeys.value.startDate],
            "MMM DD, YYYY"
          )}  -  ${formatDateTime(
            route.query[queryKeys.value.endDate],
            "MMM DD, YYYY"
          )} `
      );

      watch(range, (newValue, oldValue) => {
        if (
          newValue?.startDate !== oldValue?.startDate ||
          newValue?.endDate !== oldValue?.endDate
        ) {
          getCalendars();
          setSelectedQuickMenuKeysByRange(newValue);
          if (!props.keepOpenOnChange) isVisible.value = false;
        }
      });
      watch(
        () => route.query,
        (newQuery) => {
          if (props.queryPrefix) {
            range.value; // clear the state if the query is empty
          } else if (!newQuery.startDate && !newQuery.endDate) {
            range.value = { startDate: "", endDate: "" }; // clear the state if the query is empty
          }
        }
      );
      watch(
        () => route.query[queryKeys.value.chartStatus],
        () => {}
      );

      watch(
        () => route.query.startDate,
        () => {
          selectedValue.value =
            route.query.startDate === "" ? "today" : selectedValue.value;
        }
      );
      onMounted(() => {
        const startDate = new Date(route.query?.[queryKeys.value.startDate]);
        const endDate = new Date(route.query?.[queryKeys.value.endDate]);
        // selectedValue.value = foundItem?.key === 'last7day' ? 'week' : foundItem?.key === 'thisMonth' ? 'month' : foundItem?.key === 'undefined' ? 'year' : 'today';
        if (!isNaN(startDate) && !isNaN(endDate)) range.value = { startDate, endDate };
      });

      return {
        handleVisiblePoint,
        onClose,
        isVisible,
        range,
        getCalendars,
        formatDateTime,
        props,
        titles,
        language,
        value,
        queryKeys,
        selectedQuickMenuKeys,
        handleQuickMenuClick,
        quickMenuOptions,
        btnGroupOptions,
        handleBtnGroupChange,
        btnCompanyOptions,
        selectedValue,
      };
    },
  });
</script>

<style lang="scss">
  @use "sass:color";

  .ant-page-header-heading {
    overflow: visible;
  }

  .vc-disabled {
    background-color: white;
    color: #adadad !important;

    &:hover {
      background-color: transparent !important;
    }

    &:focus {
      background-color: transparent !important;
    }
  }

  .vc-pane-container {
    display: flex;
    flex-direction: row-reverse;
    height: 390px;
  }

  .ant-btn.ant-btn-white {
    /* // border: 1px solid #ddd; */
    background-color: #fff !important;
  }

  .footer-datepicker {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #ddd;
    padding: 15px 0;
  }

  .sDash-range-calendar {
    /* style props */
    border-radius: 4px;
    margin-right: var(--margin-right) !important;

    &.horizontal-placement-left {
      right: unset;
    }

    .vc-container {
      display: flex;
    }

    .vc-pane {
      padding: 18px 12px;
    }

    .vc-header {
      gap: 12px;
      margin-bottom: 8px !important;

      .vc-arrow {
        background: none;
        border-radius: 50%;
        width: 30px;
        color: #0476b7;
      }
    }

    .vc-pane-header-wrapper {
      .vc-header {
        padding: 0;
        margin: 18px 18px 0;
      }
    }

    .vc-pane-layout {
      .vc-header {
        padding: 0;
        margin: 0;
      }

      .vc-title {
        padding: 0 !important;
      }
    }

    &.has-left-menu {
      --left-menu-width: 170px;
      padding: 0 !important;

      .vc-pane-header-wrapper {
        padding-left: var(--left-menu-width);
      }

      .custom-footer {
        border-right: 1px solid #ddd;
        background: white;
        z-index: 2;

        .list-date-select {
          padding-right: 1px;

          .ant-menu-root {
            border-right: none;
            width: var(--left-menu-width);
            margin-top: 18px;

            .ant-menu-item {
              margin: 0 !important;
            }

            .ant-menu-selected::after,
            .ant-menu-item-selected::after {
              display: none;
            }
          }
        }

        .footer-datepicker {
          padding-right: 16px;
        }
      }
    }

    .vc-day.is-today {
      position: relative;
      z-index: 2;

      .vc-day-content {
        position: relative;

        &:not(.vc-highlight-content-solid) {
          color: #272b41 !important;

          &:not(:hover) {
            background-color: transparent !important;
          }
        }

        &::after {
          content: "";
          position: absolute;
          width: calc(100% + 3px);
          height: calc(100% + 3px);
          border-radius: 50%;
          outline: 1.25px solid #0476b7;
        }
      }
    }

    .vc-highlight {
      width: 36px;
      height: 36px;
    }

    .vc-highlight-bg-solid {
      background-color: #0476b7;
    }

    .vc-highlight-bg-light {
      opacity: 0.1;
      background: #0476b7;
    }

    .vc-day-content {
      font-size: 14px;

      &:not(.vc-disabled):hover {
        background-color: color.adjust(#0476b7, $alpha: -0.9) !important;
      }

      &.vc-highlight-content-solid {
        color: white !important;

        &:hover {
          background-color: rgba(0, 0, 0, 0.3) !important;
        }
      }
    }
  }

  button.vc-title {
    background-color: inherit;
  }

  button.cOXAzG.ant-btn.ant-btn-white.false.ant-btn.ant-btn-white.false {
    padding: 0px 8px;
    height: 34px;

    border: 1px solid;
    width: var(--width) !important;
  }

  .btn-group {
    .ant-radio-button-wrapper-checked {
      color: #ffffff !important;
      background-color: #057ebd !important;
    }
  }
</style>
