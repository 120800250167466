import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { useI18n } from 'vue-i18n'
import { format } from 'date-fns';
import { zhCN } from 'date-fns/locale';
// date time
export const formatDateTime = (date, formatString) => {
  const t = useI18n();
  const locale = t.locale.value;
  if (locale === 'zh') {
    dayjs.locale('zh-cn');
  } else {
    dayjs.locale(locale);
  }

  return dayjs(date).format(formatString).replace('+', ' •');
};

export const convertZHformatDate = (date, formatString) => {
  return format(new Date(date), formatString, { locale: zhCN });
};

export const formatMoney = ((number) => {
  if(!number) {
    return '$0.00'
  }
  return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
})

export const checkingStatus = (status) => {
  if (status === 30 || status === 15) {
    return {
      label: 'automaticallyCancelled',
      color: 'green'
    }
  }
  else if (status === 40) {
    return {
      label: 'manuallyCancelled',
      color: 'geekblue'
    }
  }
  else if (status === 31) {
    return {
      label: 'notCancelled',
      color: 'gold'
    }
  }

}

export const findCabinName = (cabin) => {
  if (cabin === "Y") {
    return 'Economy'
  } else if ( cabin === "S") {
    return 'Premium Economy';
  } else if (cabin === "C") {
    return 'Business';
  } else if(cabin === "F") {
    return 'First'
  }
}

export const findIDType = (param) => {
  if (param === 0) {
    return 'Passport'
  } else{
    return 'National ID'
  }
}
// duration
export const formatDuration =(elapsedTime)=> {
  const hours = Math.floor(elapsedTime / 60);
  const minutes = elapsedTime % 60;
  return `${hours}h ${minutes}m`;
}
//Skypoint status
export const checkStatus = (status) => {
  switch (status) {
    case "TOP_UP":
      return 'Top-Up Point'
    case "REFUNDED_FLIGHT":
      return 'Refunded for Flight booking'
    case "REFUNDED_HOTEL":
      return 'Refunded for Hotel booking'
    case "EARNED_HOTEL":
      return 'Earning Extra Point from Hotel booking'
    case "EARNED_FLIGHT":
      return 'Earning Extra Point from Flight booking'
    case "EARNED_EXTRA":
      return 'Earning Extra'
    case "REDEEMED_HOTEL":
      return 'Redeemed by Hotel'
    case "REDEEMED_FIGHT":
      return 'Redeemed by Flight'
    case "REFUND_FEE_FLIGHT":
      return 'Refund Fee Flight'
    case "REFUND_FEE_HOTEL":
      return 'Refund Fee Hotel'
    case "EARNED_FLIGHT_PENALTY":
      return 'Earned Flight Penalty'
    case "REFUND_FEE_FLIGHT_TICKET":
      return 'Refund Flight Ticket Fee'
    default:
      return 'Earnings'
  }
}
export const formatMonth = (month, formatString) => {
  const t = useI18n();
  const locale = t.locale.value;
  if (locale === 'zh') {
    dayjs.locale('zh-cn');
  } else {
    dayjs.locale(locale);
  }

  return dayjs(month).format(formatString);
};