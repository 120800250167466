import { DataService } from '../../../config/dataService/dataService';
import mutations from './mutations';
import dayjs from 'dayjs';
import axios from 'axios';
import { getItem } from '../../../utility/localStorageControl';
// import { useRouter } from 'vue-router';

// const router = useRouter()

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
const state = () => ({
    airline: {},
    airport: {},
    aircraft: {},
    region: {},
    airlineActivity: [],
    airportActivity: [],
    aircraftActivity: [],
    regionActivity: [],
    cities: {},
    countries: {},
    loading: false,
    error: null,
    });
   
const actions = {
    // Airline
    async getAirlineList({ commit }, {pageSize, pageNum, search, status}) {       
      const query = search ? `&search=${search.trim()}`: ''
      const statusFilter =  status === 'ENABLED' || status === 'DISABLED' ? `&status=${status}` : ''
      const pageSizeStr = `pageSize=${pageSize}`
      const pageNumStr = `&pageNum=${pageNum}`
        try {
            const response = await DataService.get(`airlines?${pageSizeStr}${pageNumStr}${query}${statusFilter}`);
            commit('setAirline', response.data)
          
          } catch (err) {
            commit('loginErr', err);
          }
    },

    async createAirline(_, data) {   
      let formData = new FormData();
      formData.append('code', data.code);
      formData.append('nameEn', data.nameEn);
      formData.append('nameZh', data.nameZh);
      formData.append('nameKh', data.nameKh);
      formData.append('businessNameEn', data.businessNameEn);
      formData.append('businessNameZh', data.businessNameZh);
      formData.append('businessNameKh', data.businessNameKh);
      formData.append('file', data.file);
         
      return await axios
      .post(API_ENDPOINT + `airlines`, formData, 
       {
         headers: { Authorization: `Bearer ` + getItem('token'),'Content-Type': 'multipart/form-data'}
       })
    },

    async updateAirline(_, data) {  
      let formData = new FormData();
      formData.append('id', data.id);
      formData.append('code', data.code);
      formData.append('nameEn', data.nameEn);
      formData.append('nameZh', data.nameZh);
      formData.append('nameKh', data.nameKh);
      formData.append('status', data.status);
      formData.append('enLocaleId', data.enLocaleId);
      formData.append('zhLocaleId', data.zhLocaleId);
      formData.append('khLocaleId', data.khLocaleId);
      formData.append('businessNameEn', data.businessNameEn);
      formData.append('businessNameZh', data.businessNameZh);
      formData.append('businessNameKh', data.businessNameKh);
      formData.append('file', data.file);
         
      return await axios
      .put(API_ENDPOINT + `airlines`, formData, 
       {
         headers: { Authorization: `Bearer ` + getItem('token'),'Content-Type': 'multipart/form-data'}
       })     
      // return await DataService.put(`airlines`, data);
    },

    async updateAirlineStatus(_, { id, status }) {       
      return await DataService.put(`airlines/status`, { id: typeof id !== 'string' ? id.toString() : id, status });
    },

    async deleteAirline({ commit }, {id}) {       
      try {
          const response = await DataService.delete(`airlines/${id}`);
          console.log(response)
          // commit('setCancellationFee', response.data)
        
        } catch (err) {
          commit('loginErr', err);
        }
      
  },


    async getAirlineActivityList({ commit }, param) {     
      const startDate = param?.start ? `startDate=${dayjs(param?.start).format('YYYY-MM-DD')}` : ''
      const endDate = param?.end ? `&endDate=${dayjs(param?.end).format('YYYY-MM-DD')}` : ''
      const createdBy = param?.createdBy ? `&createdBy=${param?.createdBy}`: ''
      try {
          const response = await DataService.get(`/airlines/activity?${startDate + endDate + createdBy}`);
          commit('setAirlineActivity', response.data)
        
        } catch (err) {
          commit('loginErr', err);
        }
      
    },
    // Airport
    async getAirportList({ commit }, {pageSize, pageNum, search, status}) {
      const query = search ? `&search=${search.trim()}`: ''
      const statusFilter =  status === 'ENABLED' || status === 'DISABLED' ? `&disableStatus=${status}` : ''
      const pageSizeStr = search !== '' ? `pageSize=${pageSize}` : `pageSize=${pageSize}`
      const pageNumStr = search !== '' ? `&pageNum=${pageNum}` : `&pageNum=${pageNum}`
      try {
          const response = await DataService.get(`airports?${pageSizeStr}${pageNumStr}${query}${statusFilter}`);
          commit('setAirport', response.data)
        
        } catch (err) {
          commit('loginErr', err);
        }
      
    },

    async createAirport(_, data) {
      return await DataService.post(`airports`, data);
    },
    async updateAirport(_, data) {
      // return await DataService.put(`airports`, data);
      return await axios
      .put(API_ENDPOINT + `airports`, data, 
       {
         headers: { Authorization: `Bearer ` + getItem('token'),'Content-Type': 'application/json','Accept': 'application/json'}
       }) 
    },

    async deleteAirport({ commit }, {id}) { 
      try {
          const response = await DataService.delete(`airports/${id}`);
          console.log(response)
          // commit('setCancellationFee', response.data)
        
        } catch (err) {
          commit('loginErr', err);
        }
      
  },

    async getAirportActivityList({ commit }, param ) {   
      const startDate = param?.start ? `startDate=${dayjs(param?.start).format('YYYY-MM-DD')}` : ''
      const endDate = param?.end ? `&endDate=${dayjs(param?.end).format('YYYY-MM-DD')}` : ''
      const createdBy = param?.createdBy ? `&createdBy=${param?.createdBy}`: ''    
      try {
          const response = await DataService.get(`/airports/activity?${startDate + endDate + createdBy}`);
          commit('setAirportActivity', response.data)
        
        } catch (err) {
          commit('loginErr', err);
        }
      
    },

    async getAirportCities({ commit } , search) {       
      console.log(search)
  
      try {
          const response = await DataService.get(`destinations/get-cities?&search=${search?.searchText}`);
          commit('setCityAirport', response.data)
        } catch (err) {
          commit('loginErr', err);
        }
      
    },

    async updateAirportStatus(_, data) {
      // return await DataService.put(`airports`, data);
      return await axios
      .put(API_ENDPOINT + `airports/status`, data, 
       {
         headers: { Authorization: `Bearer ` + getItem('token'),'Content-Type': 'application/json','Accept': 'application/json'}
       }) 
    },

    //Aircraft
    async getAircraftList({ commit }, {pageSize, pageNum, search}) {    
      const query = search ? `&search=${search.trim()}`: ''
      const pageSizeStr = `pageSize=${pageSize}`
      const pageNumStr = `&pageNum=${pageNum}`
      // const statusFilter =  status === 'ENABLED' || status === 'DISABLED' ? `&disableStatus=${status}` : ''   
      try {
          const response = await DataService.get(`aircraft?${pageSizeStr}${pageNumStr}${query}`);
          commit('setAircraft', response.data)
        
        } catch (err) {
          commit('loginErr', err);
        }
      
    },

    async createAircraft(_, data) {       
      return await DataService.post(`aircraft`, data);
    },
    async updateAircraft(_, data) {       
      return await DataService.put(`aircraft`, data);
    },

    async deleteAircraft({ commit }, {id}) {       
      try {
          const response = await DataService.delete(`aircraft/${id}`);
          console.log(response)
          // commit('setCancellationFee', response.data)
        
        } catch (err) {
          commit('loginErr', err);
        }
      
  },

    async getAircraftActivityList({ commit }, param ) {   
      const startDate = param?.start ? `startDate=${dayjs(param?.start).format('YYYY-MM-DD')}` : ''
      const endDate = param?.end ? `&endDate=${dayjs(param?.end).format('YYYY-MM-DD')}` : ''
      const createdBy = param?.createdBy ? `&createdBy=${param?.createdBy}`: ''    
      try {
          const response = await DataService.get(`/aircraft/activity?${startDate + endDate + createdBy}`);
          commit('setAircraftActivity', response.data)
        
        } catch (err) {
          commit('loginErr', err);
        }
      
    },

    // Region 
    async getRegionList({ commit }, {pageSize, pageNum, search, status, startDate, endDate, type}) {   
      const query = search !== '' ? `&search=${search.trim()}`: ''
      const statusFilter =  status === 'ENABLED' || status === 'DISABLED' ? `&disableStatus=${status}` : ''
      const rangeStart =  startDate !== undefined && endDate !== '' ? `&createdFrom=${ startDate } 00:00:00` : '' 
    
      const rangeEnd =   endDate !== undefined && endDate !== '' ?  `&createdTo=${ endDate } 23:59:59` : ''
      const typeData = type !== undefined && type !== '' ? `&type=${type}`: ''
      const pageSizeStr = `pageSize=${pageSize}` 
      const pageNumStr = `&pageNum=${pageNum}` 
 
      
      try {
          const response = await DataService.get(`regions?${pageSizeStr}${pageNumStr}${query}${statusFilter}${rangeStart}${rangeEnd}${typeData}`);
          commit('setRegion', response.data)
        
        } catch (err) {
          commit('loginErr', err);
        }
      
    },

    async getAirporCountries({ commit } , search) {       
      console.log(search)
  
      try {
          const response = await DataService.get(`destinations/get-countries?&search=${search?.searchText}`);
          commit('setCountryAirport', response.data)
        } catch (err) {
          commit('loginErr', err);
        }
      
  },

    async createRegion(_, data) {       
      return await DataService.post(`regions`, data);
    },
    async updateRegion(_, data) {       
      return await DataService.put(`regions`, data);
    },

    async updateRegionStatus(_, { id, status }) {       
      return await DataService.put(`regions/status`, { regionId: id, disableStatus: status });
    },

    async deleteRegion({ commit }, {id}) {       
      try {
          const response = await DataService.delete(`regions/${id}`);
          console.log(response)
          // commit('setCancellationFee', response.data)
        
        } catch (err) {
          commit('loginErr', err);
        }
      
  },

    async getRegionActivityList({ commit }, param ) {   
      const startDate = param?.start ? `startDate=${dayjs(param?.start).format('YYYY-MM-DD')}` : ''
      const endDate = param?.end ? `&endDate=${dayjs(param?.end).format('YYYY-MM-DD')}` : ''
      const createdBy = param?.createdBy ? `&createdBy=${param?.createdBy}`: ''    
      try {
          const response = await DataService.get(`/regions/activity?${startDate + endDate + createdBy}`);
          commit('setRegionActivity', response.data)
        
        } catch (err) {
          commit('loginErr', err);
        }
      
    },


}

const getters = {
    cities: state => state.cities,
    countries: state => state.countries,
    airline : state => state.airline,
    activity: state => state.airlineActivity,
    airport : state => state.airport,
    aircraft : state => state.aircraft,
    region : state => state.region,
    error: state => state.error,
    }

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};