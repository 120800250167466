import { DataService } from "../../../config/dataService/dataService";

const state = () => ({
  data: [],
  roles: [],
  user: {},
  deparments: [],
  positions: [],

  loading: false,
  error: null,
  inviteUserStatus: null,
  updateUserStatus: null,
  deletedUserStatus: null,
});

const mutations = {
  SET_DATA(state, user) {
    state.data = user;
  },
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_USER_DATA(state, user) {
    state.user = user;
  },
  SET_INVITE_USER_STATUS(state, status) {
    state.inviteUserStatus = status;
  },
  SET_DEPARTMENT(state, department) {
    state.deparments = department;
  },
  SET_POSITION(state, position) {
    state.positions = position;
  },
  SET_DELETED_USER_STATUS(state, status) {
    state.deletedUserStatus = status;
  },
  SET_UPDATE_USER_STATUS(state, status) {
    state.updateUserStatus = status;
  },
};
const actions = {
  // get all users
  async getUsers({ commit }, { pageNum, pageSize, search, status, roleId }) {
    try {
      const response = await DataService.get(
        `users?pageNum=${pageNum}&pageSize=${pageSize}&query=${search}&status=${status}&roleIds=${roleId}&locale=en`
      );
      commit("SET_DATA", response.data);
    } catch (err) {
      console.log(err);
    }
  },

  // get user by id
  async getUserById({ commit }, id) {
    try {
      const response = await DataService.get(`users/${id}`);
      commit("SET_USER_DATA", response.data);
    } catch (err) {
      console.log(err);
    }
  },
  // invite user
  async inviteUser({ commit }, { firstName, lastName, email, roleId }) {
    try {
      const response = await DataService.post(`users/invite`, {
        firstName,
        lastName,
        email,
        roleId,
      });
      commit("SET_INVITE_USER_STATUS", response.status);
      return response;
    } catch (err) {
      console.log(err);
    }
  },

  // update user
  async updateUser(
    { commit },
    {
      id,
      email,
      firstName,
      lastName,
      roleId,
      departmentId,
      positionId,
      countryCode,
      phoneNumber,
      status,
    }
  ) {
    try {
      const response = await DataService.put(`/users/information`, {
        id,
        email,
        firstName,
        lastName,
        roleId,
        departmentId,
        positionId,
        countryCode,
        phoneNumber,
        status,
      });
      console.log(response, "response");
      commit("SET_UPDATE_USER_STATUS", response.status);
    } catch (err) {
      console.log(err);
    }
  },

  // delete user
  async deleteUser({ commit }, id) {
    try {
      const response = await DataService.delete(`users/${id}`);
      commit("SET_DELETED_USER_STATUS", response.status);
      console.log(response, "response");
    } catch (err) {
      console.log(err);
    }
  },

  // resend invite
  async resendInvite({ commit }, { email }) {
    try {
      const response = await DataService.post(`/users/re-invite`, { email });
      commit("SET_INVITE_USER_STATUS", response.status);
    } catch (err) {
      console.log(err);
    }
  },
  // getRoles
  async getUserRoles({ commit }) {
    try {
      const response = await DataService.get(
        `roles?pageNum=1&pageSize=100&status=ENABLED&role=`
      );
      commit("SET_ROLES", response.data);
      console.log(response.data, "response.data");
    } catch (err) {
      console.log(err);
    }
  },
  // get department
  async getDepartment({ commit }) {
    try {
      const response = await DataService.get(`departments`);
      commit("SET_DEPARTMENT", response.data);
    } catch (err) {
      console.log(err);
    }
  },
  // get position
  async getPosition({ commit }) {
    try {
      const response = await DataService.get(`positions`);
      commit("SET_POSITION", response.data);
    } catch (err) {
      console.log(err);
    }
  },
};

const getters = {
  user: (state) => state.data,
  roles: (state) => state.roles,
  userById: (state) => state.user,
  inviteUserStatus: (state) => state.inviteUserStatus,
  updateUserStatus: (state) => state.updateUserStatus,
  departments: (state) => state.deparments,
  positions: (state) => state.positions,
  deletedUserStatus: (state) => state.deletedUserStatus,
};

export default {
  actions,
  state,
  getters,
  mutations,
};
