import mutations from './mutations';
import { DataService } from '../../../config/dataService/dataService';

const state = () => ({
  destinations: [],
  successStatus: null,
  loading: false,
  error: null,
});

const createState = () => ({
  status: ''
});

const actions = { 

  // get Roles List
  async getRoles({ commit } ,{ pageNum, limit, status, search}) {       
    try {
        const response = await DataService.get(`roles?pageNum=${pageNum}&pageSize=${limit}&status=${status || ''}&role=${search || ''}`);
        commit('setRoles', response.data)
      } catch (err) {
        commit('loginErr', err);
      }
  },

  async getPermissions({ commit }) {       
    try {
        const response = await DataService.get(`permissions`);
        commit('setPermissions', response.data)
      } catch (err) {
        commit('loginErr', err);
      }
  },

  // create New Role
  async createRole({ commit },payload) {
    try {
      const response = await DataService.post('roles',{
        "role": payload.role,
        "description": payload.description,
        "status": payload.status
      });
      
      if(response.status === 200){

        commit('setSuccessStatus', response.status)
        console.log('Create Suceessfully')
        return response
      }else{
        console.log(response.data)
        console.log(response.data.message)
      }
    } catch (err) {
      commit('loginErr', err);
    }
  },

  // Update New Role
  async updateRole({ commit },payload) {
    try {
      const response = await DataService.put(`roles/${payload.id}`,{
        "role": payload.role,
        "description": payload.description,
        "status": payload.status
      });
      if(response.status === 200){

        commit('setSuccessStatus', response.status)
        console.log('Create Suceessfully')
        
      }else{
        console.log(response.data)
        console.log(response.data.message)
      }
    } catch (err) {
      commit('loginErr', err);
    }
  },

  // Update New Role
  async getRoleDetail({ commit },payload) {
    try {
      const response = await DataService.get(`roles/${payload.id}`);
      if(response.status === 200){
        commit('setRoleDetail', response.data)
        //console.log('response.data', response.data)
      }else{
        console.log(response.data)
        console.log(response.data.message)
      }
    } catch (err) {
      commit('loginErr', err);
    }
  },

  // Update Role permission
  async rolePermissionUpdate({ commit },payload) {
  try {
    const response = await DataService.post(`roles/${payload.roleId}/permissions`, {
      "permissions": payload.permissions
    });
    if(response?.status === 200){
      commit('setSuccessStatus', response.status)
      console.log('Create Suceessfully')
    }else{
      commit('setSuccessStatus', response.status)
      console.log(response.data)
      console.log(response.data.message)
    }
  } catch (err) {
    commit('loginErr', err);
  }
},

// Update Multiple permission
async multiplePermissionUpdate({ commit },payload) {
  try {
    const response = await DataService.put(`/roles/permissions`,payload);
    if(response?.status === 200){
      commit('setSuccessStatus', response.status)
      console.log('Create Suceessfully')
    }else{
      commit('setSuccessStatus', response.status)
      console.log(response.data)
      console.log(response.data.message)
    }
  } catch (err) {
    commit('loginErr', err);
  }
},


  //  delete top destinations
  async deleteRoles({ commit }, id) {
    try {
      return await DataService.delete(`roles/${id}`);
      // console.log('deleteRoles response', response);
      
      // if(response?.status === 200){
      //   commit('setSuccessStatus', response?.status)
      //   console.log('Create Suceessfully')
      // }else{
      //   commit('setFailedStatus', response?.code)
      //   console.log(response?.data)
      //   console.log(response?.data.message)
      // }
      // return response;
    } catch (err) {
      //console.log('deleteRoles err', err);
      commit('setFailedStatus', 400)
    }
  },

};

//getters
const getters = {
  destinations: state => state.destinations,
  loading: state => state.loading,
  status: createState => createState.status,
  successStatus: state => state.successStatus,
  failedStatus: state => state.failedStatus
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
  createState
};