import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import i18n from './i18n';
// import { formatDateTime } from '@/components/utilities/skybookingBo';
import './static/css/style.css';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
// Vue 3rd party plugins
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/plugins/maps';
import '@/core/plugins/masonry';
import '@/core/plugins/apexcharts';
import '@/core/components/custom';
import '@/core/components/style';
dayjs.locale('zh-cn');
app.config.productionTip = false;
app.use(store);
app.use(router);
app.use(i18n());
// app.use(formatDateTime)
app.mount('#app');
