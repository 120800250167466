import { DataService } from "../../../config/dataService/dataService";
import dayjs from 'dayjs';
// import axios from 'axios'
const state = () => ({
    loading: false,
    data: null,
    error: null,
    exportData: '',
    pnrDataDetail: null,
    activityData: null,
    concalData: null
});

const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_DATA(state, data) {
        state.data = data;
    },
    SET_EXPORT_DATA(state, data) {
        state.exportData = data;
    },
    SET_PNR_DATA(state, data) {
        state.pnrDataDetail = data;
    },
    SET_ACTIVITY_DATA(state, data) {
        state.activityData = data;
    },
    SET_CANCEL_DATA(state, data) {
        state.concalData = data;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

const actions = {
    async fetListPNR({ commit }, { route, status }) {
        const pageNum = route?.query?.pageNum || 1
        const pageSize = route?.query?.pageSize || 10
        const startDate = route?.query?.start ? `&startDate=${dayjs(route?.query?.start).format('YYYY-MM-DD')}` : '';
        const endDate = route?.query?.end ? `&endDate=${dayjs(route?.query?.end).format('YYYY-MM-DD')}` : '';
        const statusParam = status?.value?.length > 0 ? `&status=${status.value}` : '';
        try {
            commit('SET_LOADING', true);
            const data = await DataService.get(`/cancelled-pnr?pageNum=${pageNum}&pageSize=${pageSize}${startDate + endDate + statusParam}`);
            commit('SET_DATA', data?.data);
            commit('SET_LOADING', false);
        } catch (error) {
            commit('SET_ERROR', error);
            commit('SET_LOADING', false);
        }
    },

    async exportCancelledPNR({ commit }, { route, status }) {
        const pageNum = route?.query?.pageNum || 1
        const pageSize = route?.query?.pageSize || 10
        const startDate = route?.query?.start ? `&startDate=${dayjs(route?.query?.start).format('YYYY-MM-DD')}` : '';
        const endDate = route?.query?.end ? `&endDate=${dayjs(route?.query?.end).format('YYYY-MM-DD')}` : '';
        const statusParam = status?.value?.length > 0 ? `&status=${status.value}` : '';

        try {
            commit('SET_LOADING', true);
            const req = await DataService.get(`/cancelled-pnr/export?pageNum=${pageNum}&pageSize=${pageSize}${startDate + endDate + statusParam}`, "blob",);
            var blob = new Blob([req.data], {
                type: req.headers["content-type"],
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `report-${dayjs(new Date().getTime()).format('YYYY-MM-DD')}.xlsx`;
            link.click();

            commit('SET_LOADING', false);
        } catch (error) {
            commit('SET_ERROR', error);
            commit('SET_LOADING', false);
        }
    },

    async fetPNRDetail({ commit }, param) {
        console.log(param);
        try {
            commit('SET_LOADING', true);
            const data = await DataService.get(`/bookings/${param}`);
            // const data = await DataService.get(`/bookings/OXICON`);
            commit('SET_PNR_DATA', data?.data);
            commit('SET_LOADING', false);
        } catch (error) {
            commit('SET_ERROR', error);
            commit('SET_LOADING', false);
        }
    },

    async fetPNRActivity({ commit }, param) {
        try {
            commit('SET_LOADING', true);
            const data = await DataService.get(`/bookings/activity?bookingId=${param}`);
            commit('SET_ACTIVITY_DATA', data?.data);
            commit('SET_LOADING', false);
        } catch (error) {
            commit('SET_ERROR', error);
            commit('SET_LOADING', false);
        }
    },
    async cancelPNR({ commit }, param) {
        try {
            commit('SET_LOADING', true);
            const data = await DataService.put('cancelled-pnr', param);
            commit('SET_CANCEL_DATA', data?.data);
            commit('SET_LOADING', false);
        } catch (error) {
            commit('SET_ERROR', error);
            commit('SET_LOADING', false);
        }
    },
}

export default {
    actions,
    mutations,
    state
};
