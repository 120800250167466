// store.js


import mutations from './mutations';
import { DataService } from '../../../config/dataService/dataService';

const state = () => ({
    destinations: [],
    country: [],
    successStatus: null,
    loading: false,
    error: null,
    destinationsHotel: [],
    groupName: []
    });

const createState = () => ({
    countryId: null,
    cityId: null,
    airportId: null,
    status: ''
    });
const actions = {
    // get top destinations
    async getTopDestinations({ commit } ,{ pageNum, limit , countryId, status, search}) {       
  
        try {
            const response = await DataService.get(`destinations?pageNum=${pageNum}&pageSize=${limit}&countryId=${countryId}&status=${status || ''}&search=${search || ''}`);
            commit('setDestinations', response.data)
          } catch (err) {
            commit('loginErr', err);
          }
        
    },

    // get country 
    async getCountries({ commit }) {
        try {
            const response = await DataService.get('destinations/get-countries');
            commit('setCountry', response.data)
          } catch (err) {
            commit('loginErr', err);
          }
    },

    // get city
    async getCities({ commit }, countryId) {
      try {
          const response = await DataService.get(`destinations/get-cities?countryId=${countryId}&search=&locale=en`);
          commit('setCity', response.data)
        } catch (err) {
          commit('loginErr', err);
        }
    },
    // get airport
    async getAirports({ commit }, cityId) {
      try {
          const response = await DataService.get(`destinations/get-airports?search=&cityId=${cityId}&locale=en`);
          commit('setAirport', response.data)
        } catch (err) {
          commit('loginErr', err);
        }
    },

    // post top destinations
    async postTopDestinations({ commit },payload) {
        try {
            const response = await DataService.post('destinations',{
              "countryId": payload.countryId,
              "cityId": payload.cityId,
              "airportId": payload.airportId,
              "status": payload.status
          });
          if(response.status === 200){
            commit('setSuccessStatus', response.status)
            console.log('Create Suceessfully')
           
          }else{
            console.log(response.data)
            console.log(response.data.message)
          }
          
          } catch (err) {
            commit('loginErr', err);
          }
    },

   // update top destinations
    async updateTopDestinations({ commit},{id,countryId,cityId,airportId,status }) {
        try {
            const response = await DataService.put(`destinations/${id}`,{
              "countryId": countryId,
              "cityId": cityId,
              "airportId": airportId,
              "status": status
          }).then(response => {

            if(response.status === 200){
              commit('setSuccessStatus', response.status)
              console.log('Edit Suceessfully')
            }
          
          });
          if(response.status !== 200){
            console.log(response.data + 'error')
            alert(response.data.message)
          }else{       
            commit('setDestinations', response.data)
          }
          } catch (err) {
            commit('loginErr', err);
          }
    },
    
  //  delete top destinations
    async deleteTopDestinations({ commit }, id) {
        try {
            const response = await DataService.delete(`destinations/${id}`);
            console.log(response.data)
            commit('setDestinations', response.data)
          } catch (err) {
            commit('loginErr', err);
          }
    },
    
   // create top destinations action
    updateCountryId({ commit }, countryId) {
      commit('setUpdateCountryId', countryId);
      console.log(countryId)
    },
    updateCityId({ commit }, cityId) {
      commit('setUpdateCityId', cityId);
      
    },
    updateAirportId({ commit }, airportId) {
      commit('setUpdateAirportId', airportId);
    },
    updateStatus({ commit }, status) {
      commit('setUpdateStatus', status);
    },
    // Hotel top destinations
    async getHotelTopDestinations({ commit } ,{ pageNum, limit , status, search}) {       
  
      try {
          const response = await DataService.get(`hotel_top_destination?pageNum=${pageNum}&pageSize=${limit}${status === 'ENABLED' || status === 'DISABLED' ? `&status=${status}`: ''}&search=${search || ''}`);
          commit('setHotelDestinations', response.data)
        } catch (err) {
          commit('loginErr', err);
        }
      
    },
    // get country hotel
    async getHotelCountries({ commit }) {
      try {
          const response = await DataService.get('hotel_city_country/country');
          commit('setHotelCountry', response.data)
        } catch (err) {
          commit('loginErr', err);
        }
    },

    // get city hotel
    async getHotelTopCities({ commit }, countryCode) {
      try {
          const response = await DataService.get(`hotel_city_country/city?countryCode=${countryCode}`);
          commit('setHotelCity', response.data)
        } catch (err) {
          commit('loginErr', err);
        }
    },

    // get hotel groupName
    async getGroupName({ commit }) {
      try {
        const response = await DataService.get(`hotel_top_destination/continent`);
        commit('setHotelGroupName', response.data)
      } catch (err) {
        commit('loginErr', err);
      }
    },

    // post top destinations
    async postHotelTopDestinations({ commit },payload) {
      try {
          const response = await DataService.post('hotel_top_destination',{
            "countryCode": payload.countryCode,
            "cityCode": payload.cityCode,
            "continentId": payload.continentId,
            "status": payload.status
        });
        if(response.status === 200){
          commit('setSuccessStatus', response.status)
          console.log('Create Suceessfully')
        
        }else{
          console.log(response.data)
          console.log(response.data.message)
        }
        
        } catch (err) {
          commit('loginErr', err);
        }
    },

    // update hotel top destinations
    async updateHotelTopDestinations({ commit},{ id, continentId, countryCode, cityCode, status }) {
      try {
          const response = await DataService.put(`hotel_top_destination`,{
            "id": id,
            "continentId": continentId,
            "countryCode": countryCode,
            "cityCode": cityCode,
            "status": status
        }).then(response => {

          if(response.status === 200){
            commit('setSuccessStatus', response.status)
          }
        
        });
        if(response.status !== 200){
          console.log(response.data + 'error')
        }else{       
          commit('setHotelDestinations', response.data)
        }
        } catch (err) {
          commit('loginErr', err);
        }
    },

    //  delete hotel top destinations
    async deleteHotelTopDestinations({ commit }, id) {
      try {
        const response = await DataService.delete(`hotel_top_destination/${id}`);
          commit('setHotelDestinations', response.data)
        } catch (err) {
          commit('loginErr', err);
        }
    },
};
    //getters
    const getters = {
        destinations: state => state.destinations,
        countries: state => state.country,
        cities: state => state.city,
        airports: state => state.airport,
        loading: state => state.loading,
        countryId: createState => createState.countryId,
        cityId: createState => createState.cityId,
        airportId: createState => createState.airportId,
        status: createState => createState.status,
        successStatus: state => state.successStatus,
        destinationsHotel: state => state.destinationsHotel,
        groupName: state => state.groupName,
        hotelCountries: state => state.hotelCountry,
        hotelCities: state => state.hotelCity,
    };
export default {
    namespaced: false,
    state,
    actions,
    mutations,
    getters,
    createState
};