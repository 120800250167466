
import { DataService } from '../../../config/dataService/dataService';
import mutations from './mutations';
const state = () => ({
    profitSettings: [],
    hotelProfitSettings: [],
    successStatus: null,
    loading: false,
    error: null,
    });

const actions = {
    async getProfitSetting({ commit }) {       
        try {
            const response = await DataService.get(`profit-settings`);
            commit('setProfitSetting', response.data)
          
        } catch (err) {
          commit('loginErr', err);
        }
    },
    async updateProfitSetting({ commit }, payload) {
        try {
            const response = await DataService.put(`profit-settings`, payload);
            commit('setProfitSetting', response.data)
            if(response.status === 200){
              commit('setSuccessStatus', response.status)
              console.log('Edit Suceessfully')
            }
          
          
          } catch (err) {
            commit('loginErr', err);
          }
        
    },
    async getHotelProfitSetting({ commit }) {
      try {
          const response = await DataService.get(`hotel_profit_setting`);
          commit('setHotelProfitSetting', response.data)
        
      } catch (err) {
        commit('loginErr', err);
      }
    },
    async updateHotelProfitSetting({ commit }, payload) {
      try {
          const response = await DataService.put(`hotel_profit_setting`, payload);
          commit('setHotelProfitSetting', response.data)
          if(response.status === 200){
            commit('setSuccessStatus', response.status)
            console.log('Edit Suceessfully')
          }
          return response
        } catch (err) {
          commit('loginErr', err);
        }
  }
}

const getters = {
    profitSetting: state => state.profitSettings,
    getSuccessStatus: state => state.successStatus,
    hotelProfitSetting: state => state.hotelProfitSettings
};

    export default {
        namespaced: false,
        state,
        getters,
        actions,
        mutations
    };