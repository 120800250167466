import { createStore } from "vuex";

import themeLayout from './modules/themeLayout/actionCreator';
import users from './modules/users';
import chartContent from './modules/chartContent/actionCreator';
import profile from './modules/profile/actionCreator';
import crud from './modules/crud/axios/actionCreator';
import auth from './modules/authentication/axios/actionCreator';
import dashboard from './modules/dashboard/actionCreator';
import customers from './modules/customers/actionCreator';
import topDestination from './modules/topDestination/actionTopDestination'
import popularCity from './modules/popularCity';
import profitSetting from './modules/profitSetting/actionProfitStting';
import cancellationFee from './modules/cancellation-fee/actionCancellationFee';
import contentManagement from './modules/contentManagement/actionContentManagement';
import languages from './modules/languages/actionCreator';
import orders from './modules/orders';
import currency from './modules/currency';
import company from './modules/company/action';
import PNRCancellation from './modules/PNRCancellation';
import paymentMethodFee from './modules/paymentMethodFee';
import rolePermission from './modules/rolePermission/actionRolePermission';
import userManagement from "./modules/userManagement";
import userProfile from "./modules/userProfile";
import skyPoint from './modules/skyPoint/actionCreator';

export default createStore({
  modules: {
    themeLayout,
    users,
    chartContent,
    profile,
    crud,
    auth,
    dashboard,
    customers,
    topDestination,
    popularCity,
    profitSetting,
    cancellationFee,
    contentManagement,
    languages,
    orders,
    currency,
    company,
    PNRCancellation,
    paymentMethodFee,
    rolePermission,
    userManagement,
    userProfile,
    skyPoint
  },
});
