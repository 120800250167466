export default {
    setAirline(state, airline) {
        state.airline = airline
    },
  
    setAirport(state, airport) {
        state.airport = airport
    },
    setCityAirport(state, cities) {
        state.cities = cities
    },
    setCountryAirport(state, countries) {
        state.countries = countries
    },
    setAircraft(state, aircraft) {
        state.aircraft = aircraft
    },
    setRegion(state, region) {
        state.region = region
    },

    setAirlineActivity(state, activity) {
        state.airlineActivity = activity
    },
    setAirportActivity(state, activity) {
        state.airportActivity = activity
    },
    setAircraftActivity(state, activity) {
        state.aircraftActivity = activity
    },
    setRegionActivity(state, activity) {
        state.regionActivity = activity
    },
  
    setCreateError(state, error ) {
       state.error = error
    }
}