import mutations from './mutations';
import Cookies from 'js-cookie';
import { LoginService } from '@/config/dataService/loginService';

const state = () => ({
  login: Cookies.get('logedIn'),
  token: null,
  loading: false,
  error: null,
});

const actions = {
  async login({ commit }, body) {
    try {
      commit('loginBegin');
      const query = await LoginService.post('oauth/token', body);
      if (query?.data?.access_token) {
        localStorage.setItem('token', query?.data?.access_token)
        localStorage.setItem('loginStatus', 'true')
        Cookies.set('logedIn', true);
        commit('authToken', query?.data?.access_token);
        commit('loginSuccess', true);
      }
      return query
    } catch (err) {
      console.log('error ', err);
      commit('loginErr', err);
    }
  },
  async logOut({ commit }) {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpire');
      localStorage.removeItem('loginStatus', 'false')
      commit('logoutBegin');
      Cookies.remove('logedIn');
      commit('logoutSuccess', null);
    } catch (err) {
      commit('logoutErr', err);
    }
  },
  // forgot password
  async forgotPassword({ commit }, body) {
    try {
      commit('forgotBegin');
      const query = await LoginService.post('users/reset-password', body);
      commit('forgotSuccess', true);
      return query
    } catch (err) {
      commit('loginErr', err);
    }
  },
  // reset password
  async resetPassword({ commit }, body) {
    try {
      commit('resetBegin');
      const query = await LoginService.put('users/reset-password', body);
      commit('resetSuccess', true);
      return query
    } catch (err) {
      commit('loginErr', err);
    }
  },
  // change password
  async changePassword({ commit }, body) {
    try {
      commit('resetBegin');
      const query = await LoginService.put('users/activate-password', body);
      commit('resetSuccess', true);
      return query
    } catch (err) {
      commit('loginErr', err);
    }
  }
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
