import { DataService } from "../../../config/dataService/dataService";
import axios from 'axios';
import { getItem } from '../../../utility/localStorageControl';
// import dayjs from 'dayjs';
const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
const state = () => ({
  loading: false,
  data: null,
  error: null,
  crudData: null,
  activityData: null,
  userActivity: null,
});

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_CRUD_DATA(state, data) {
    state.crudData = data;
  },
  SET_ACTIVITY_DATA(state, data) {
    state.activityData = data;
  },
  SET_ACTIVITY_USER_DATA(state, data) {
    state.userActivity = data;
  }
};

const actions = {
  async fetchListPayment({ commit }, { pages, isStatus, searchQuery }) {
    const pageNum = pages?.query?.pageNum || 1
    const pageSize = pages?.query?.pageSize || 10
    const status = isStatus?.value?.toUpperCase()
    const query = searchQuery?.value ? `&search=${searchQuery?.value}` : ''
    try {
      commit('SET_LOADING', true);
      const data = await DataService.get(`/payment-methods?${status === 'ENABLED' || status === 'DISABLED' ? `&status=${status}` : ''}${searchQuery?.value ? query : `&pageSize=${pageSize}&pageNum=${pageNum}`}`);

      commit('SET_DATA', data?.data);

      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_ERROR', error);
      commit('SET_LOADING', false);
    }
  },
  
  async paymentMethodCrud({ commit }, data) {
    try {
      commit('SET_LOADING', true); // Set loading state to true

      let formData = new FormData();
      formData.append('method', data.method);
      formData.append('code', data.code);
      formData.append('type', data.type);
      formData.append('paymentGatewayCode', data.paymentGatewayCode);
      formData.append('percentage', data.percentage);
      formData.append('status', data.status);
      formData.append('file', data.file);
      await axios
        .post(API_ENDPOINT + `payment-methods`, formData, 
         {
           headers: { Authorization: `Bearer ` + getItem('token'),'Content-Type': 'multipart/form-data'}
         })
        .then((res) => {
          console.log(res.status);
          commit('SET_CRUD_DATA', res.status);
        })
        .catch((err) => {
          console.log(err);
          //toast.error(err.response.data.message);
        });
      // const response = await DataService.put('/payment-methods', formData, { headers : { 'Content-Type': 'multipart/form-data' } });
      
      commit('SET_LOADING', false);
    } catch (error) {
      // Handle errors by committing them to the Vuex store
      commit('setError', error);
    }
  },
  async paymentMethodUpdateCrud({ commit }, data) {
    try {
      commit('SET_LOADING', true); // Set loading state to true
      // Construct the API endpoint based on the CRUD operation type and ID
      let formData = new FormData();
      formData.append('id', data.id)
      formData.append('method', data.method);
      formData.append('code', data.code);
      formData.append('type', data.type);
      formData.append('paymentGatewayCode', data.paymentGatewayCode);
      formData.append('percentage', data.percentage);
      formData.append('status', data.status);
      formData.append('file', data.file);
      await axios
        .put(API_ENDPOINT + `payment-methods`, formData, 
         {
           headers: { Authorization: `Bearer ` + getItem('token'),'Content-Type': 'multipart/form-data'}
         })
        .then((res) => {
          console.log(res.status);
          commit('SET_CRUD_DATA', res.status);
        })
        .catch((err) => {
          console.log(err);
          //toast.error(err.response.data.message);
        });
      // const response = await DataService.put('/payment-methods', formData, { headers : { 'Content-Type': 'multipart/form-data' } });
      
      commit('SET_LOADING', false);
    } catch (error) {
      // Handle errors by committing them to the Vuex store
      commit('setError', error);
    }
  },
  async paymentMethodCrudDelete({ commit }, param) {
    try {
      commit('SET_LOADING', true); // Set loading state to true

      const response = await DataService.delete(`payment-methods/${param?.id}`);
      commit('SET_CRUD_DATA', response?.status);
      commit('SET_LOADING', false);
      // commit('setCancellationFee', response.data)
    
    } catch (err) {
      commit('loginErr', err);
    }
  }
};

export default {
  actions,
  mutations,
  state
};
