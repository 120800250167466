export default {
  dashboardBegin(state) {
    state.dashboards = true;
  },
  dashboardSuccess(state, data) {
    state.loading = false;
    state.dashboards = data;
  },

  dashboardErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  calendarsBegin(state) {
    state.calendars = true;
  },
  calendarSuccess(state, data) {
    state.loading = false;
    state.calendars = data;
  },
  
  calendarsErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  
  userBegin(state) {
    state.user = true;
  },
  userSuccess(state, data) {
    state.loading = false;
    state.user = data;
  },

  userErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  customerStatisticsBegin(state) {
    state.customerStatistics = true;
  },
  customerStatisticsSuccess(state, data) {
    state.loading = false;
    state.customerStatistics = data;
  },
  orderStatisticsBegin(state) {
    state.orderStatistics = true;
  },
  orderStatisticsSuccess(state, data) {
    state.loading = false;
    state.orderStatistics = data;
  },
};
