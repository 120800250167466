export default {
    setCancellationFee(state, cancellationFees) {
        state.cancellationFees = cancellationFees
    },
    // requestCreate(state, cancellationFees ) {
    //     state.cancellationFees = cancellationFees

    // },
    setCreateError(state, error ) {
       state.error = error
    },
    setHotelCancellationFee(state, cancellationHotelFees) {
        state.cancellationHotelFees = cancellationHotelFees
    }
}