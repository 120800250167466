import { DataService } from "../../../config/dataService/dataService";
import dayjs from 'dayjs';
const state = () => ({
  loading: false,
  data: null,
  error: null,
  crudData: null,
  activityData: null,
  userActivity: null,
});

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_ERROR(state, error) {
    state.crudData = error;
  },
  SET_CRUD_DATA(state, data) {
    state.crudData = data;
  },
  SET_ACTIVITY_DATA(state, data) {
    state.activityData = data;
  },
  SET_ACTIVITY_USER_DATA(state, data) {
    state.userActivity = data;
  }
};

const actions = {
  async fetListCurrency({ commit }, { pages, isStatus, searchQuery }) {
    const pageNum = searchQuery?.value ? '' : pages?.query?.pageNum || 1
    const pageSize = searchQuery?.value ? '' : pages?.query?.pageSize || 10
    const status = isStatus?.value?.toUpperCase()
    const query = searchQuery?.value ? `&query=${searchQuery?.value}` : ''
    try {
      commit('SET_LOADING', true);
      const data = await DataService.get(`/currencies?pageNum=${pageNum}&pageSize=${pageSize}${status === 'ENABLED' || status === 'DISABLED' ? `&status=${status}` : ''}${query}`);

      commit('SET_DATA', data?.data);

      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_ERROR', error);
      commit('SET_LOADING', false);
    }
  },

  async currencyCrud({ commit }, param) {
    const body = param.formState;  // Assuming `formState` contains the data for the request
    const type = param.type;       // Type of CRUD operation (e.g., 'post', 'put', 'delete')
    try {
      commit('SET_LOADING', true); // Set loading state to true

      // Construct the API endpoint based on the CRUD operation type and ID
      const endpoint = `/currencies${type === 'put' || type === 'delete' ? `/${param?.id}` : ''}`;
      const data = await DataService[type](endpoint, body);
      commit('SET_CRUD_DATA', data?.status);
      commit('SET_LOADING', false);
    } catch (error) {
      // Handle errors by committing them to the Vuex store
      const statusCode = {
        status: error?.response?.status
      }
      commit('setError', statusCode);
    }
  },

  async fetActivity({ commit }, param) {
    const startDate = param?.start ? `startDate=${dayjs(param?.start).format('YYYY-MM-DD')}` : ''
    const endDate = param?.end ? `&endDate=${dayjs(param?.end).format('YYYY-MM-DD')}` : ''
    const createdBy = param?.createdBy ? `&createdBy=${param?.createdBy}`: ''
    try {
      commit('SET_LOADING', true);
      const data = await DataService.get(`/currencies/activity?${startDate + endDate + createdBy}`);
      commit('SET_ACTIVITY_DATA', data?.data);
      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_ERROR', error);
      commit('SET_LOADING', false);
      
    }
  },

async fetActivityUser({ commit }) {
  try {
    commit('SET_LOADING', true);
    const data = await DataService.get(`/currencies/activity/users`);
    commit('SET_ACTIVITY_USER_DATA', data?.data);
    commit('SET_LOADING', false);
  } catch (error) {
    commit('SET_ERROR', error);
    commit('SET_LOADING', false);
  }
},
};

export default {
  actions,
  mutations,
  state
};
