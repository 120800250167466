export default {
  skypointOverviewBegin(state) {
    state.overview = true;
  },
  skypointOverviewSuccess(state, data) {
    state.loading = false;
    state.overview = data;
  },
  skypointTransactionListBegin(state) {
    state.skypoints = true;
  },
  skypointTransactionListSuccess(state, data) {
    state.loading = false;
    state.skypoints = data;
  },
  skypointApproveStatus(state, status) {
    state.pointApproveStatus = status;
  },
  skypointTransactionDetailBegin(state) {
    state.detail = true;
  },
  skypointTransactionDetailSuccess(state, data) {
    state.loading = false;
    state.detail = data;
  },
  skypointReferenceDetailBegin(state) {
    state.referenceDetail = true;
  },
  skypointReferenceDetailSuccess(state, data) {
    state.loading = false;
    state.referenceDetail = data;
  },
  skypointTopUpStatus(state, status) {
    state.pointTopUpStatus = status;
  },
  fetchCustomerBegin(state) {
    state.customerData = true;
  },
  fetchCustomerSuccess(state, data) {
    state.loading = false;
    state.customerData = data;
  },
};
