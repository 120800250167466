import { DataService } from "../../../config/dataService/dataService";
const lang = localStorage.getItem('lang')
const state = () => ({
  loading: false,
  data: null,
  error: null,
  crudData: null,
  airPortData: null,
  cityData: null,
  hotelPopularCity: null,
  hotelCity: null
});

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_CRUD_DATA(state, data) {
    state.crudData = data;
  },
  SET_AIRPORT_DATA(state, data) {
    state.airPortData = data;
  },
  SET_CITY_DATA(state, data) {
    state.cityData = data;
  },
  SET_CLEAR_DATA(state) {
    state.airPortData = null;
  },
  SET_HOTEL_POPULAR_DATA(state, data) {
    state.hotelPopularCity = data;
  },
  SET_HOTEL_CITY_DATA(state, data) {
    state.hotelCity = data;
  }
};

const actions = {
  async getPopularCity({ commit }, {pages, isStatus, searchQuery, countryQuery}) {
    const pageNum = pages?.query?.pageNum || 1
    const pageSize = pages?.query?.pageSize || 10
    const status = isStatus?.value?.toUpperCase()
    const country = (countryQuery?.value !== 'Select Country' && countryQuery?.value !== '选择国家') ? `&countryId=${countryQuery?.value}` : '';
    const query = searchQuery?.value ? `&query=${searchQuery?.value}`: ''

    try {
      commit('SET_LOADING', true);
      const data = await DataService.get(`/cities?pageNum=${pageNum}&pageSize=${pageSize}${status === 'ENABLED' || status === 'DISABLED' ? `&status=${status}`: ''}${query}${country}`);

      commit('SET_DATA', data?.data);

      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_ERROR', error);
      commit('SET_LOADING', false);
    }
  },

  async popularCityCrud({ commit }, param) {
    const body = param.formState;  // Assuming `formState` contains the data for the request
    const type = param.type;       // Type of CRUD operation (e.g., 'post', 'put', 'delete')
    const id = param.id;           // ID of the city (used for 'put' and 'delete' operations)
  
    try {
      commit('SET_LOADING', true); // Set loading state to true

      // Construct the API endpoint based on the CRUD operation type and ID
      const endpoint = `/cities${type === 'put' || type === 'delete' ? `/${id}` : ''}`;
  
      // Make the API request using the DataService[type] method
      const data = await DataService[type](endpoint, body);
      // Commit the status received from the API to the Vuex store
      commit('SET_CRUD_DATA', data?.status);
  
      commit('SET_LOADING', false); 
    } catch (error) {
      // Handle errors by committing them to the Vuex store
      const statusCode = {
        status: error?.response?.status
      }
      commit('SET_CRUD_DATA', statusCode);
    }
  },

  async fetAirport({ commit }, param) {
    const cityId = param ? `?cityId=${param}`: ''
    try {
      commit('SET_LOADING', true);
      const data = await DataService.get(`/destinations/get-airports${cityId}`);
      commit('SET_AIRPORT_DATA', data?.data);
      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_ERROR', error);
      commit('SET_LOADING', false);
    }
  },
  async fetCity({ commit }, param) {
    const pageNum = param ? '' : 1
    const pageSize = param ? '' : 30
    const query = param ? `&search=${param}`: ''
    try {
      commit('SET_LOADING', true);
      const data = await DataService.get(`/destinations/get-cities?pageNum=${pageNum}&pageSize=${pageSize}${query}&locale=${lang}`);
      commit('SET_CITY_DATA', data?.data?.rows);
      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_ERROR', error);
      commit('SET_LOADING', false);
    }
  },
  async clearData({ commit }) {
      commit('SET_CLEAR_DATA')
  },
  async getHotelCities({ commit }, param) {
    const pageNum = param ? '' : 1
    const pageSize = param ? '' : 30
    const query = param ? `&cityName=${param}`: ''
    try {
      commit('SET_LOADING', true);
      const data = await DataService.get(`/hotel_city_country/city?pageNum=${pageNum}&pageSize=${pageSize}${query}&locale=${lang}`);
      commit('SET_HOTEL_CITY_DATA', data?.data);
      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_ERROR', error);
      commit('SET_LOADING', false);
    }
  },
  async getPopularCityHotel({ commit }, {pages, isStatus, searchQuery}) {
    console.log('searchQuery ', searchQuery);
    const pageNum = pages?.query?.pageNum || 1
    const pageSize = pages?.query?.pageSize || 10
    const status = isStatus?.value?.toUpperCase()
    const query = searchQuery?.value ? `&search=${searchQuery?.value}`: ''

    const pageSizeStr = searchQuery !== '' ? "" : `pageSize=${pageSize}`
    const pageNumStr = searchQuery !== '' ? "" : `&pageNum=${pageNum}` 

    try {
      commit('SET_LOADING', true);
      const data = await DataService.get(`/hotel_popular_city?${pageSizeStr}${pageNumStr}${status === 'ENABLED' || status === 'DISABLED' ? `&status=${status}`: ''}${query}`);

      commit('SET_HOTEL_POPULAR_DATA', data?.data);

      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_ERROR', error);
      commit('SET_LOADING', false);
    }
  },
  async popularCityCrudHotel({ commit }, param) {
    const body = param.formState;  // Assuming `formState` contains the data for the request
    const type = param.type;       // Type of CRUD operation (e.g., 'post', 'put', 'delete')
    const id = param.id;           // ID of the city (used for 'put' and 'delete' operations)
  
    try {
      commit('SET_LOADING', true); // Set loading state to true

      // Construct the API endpoint based on the CRUD operation type and ID
      const endpoint = `/hotel_popular_city${type === 'delete' ? `/${id}` : ''}`;
  
      // Make the API request using the DataService[type] method
      const data = await DataService[type](endpoint, body);
      // Commit the status received from the API to the Vuex store
      commit('SET_CRUD_DATA', data?.status);
  
      commit('SET_LOADING', false); 
    } catch (error) {
      // Handle errors by committing them to the Vuex store
      const statusCode = {
        status: error?.response?.status
      }
      commit('SET_CRUD_DATA', statusCode);
    }
  },
};

export default {
  actions,
  mutations,
  state
};
