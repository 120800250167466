const routes = [
  {
    path: 'login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/view/authentication/SignIn.vue'),
  },
  {
    path: 'register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/view/authentication/Signup.vue'),
  },
  {
    path: 'forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '@/view/authentication/ForgotPassword.vue'),
  },
  {
    path: 'reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "resetPassword" */ '@/view/authentication/ResetPassword.vue'),
  },
  {
    path: 'changePassword',
    name: 'changePassword',
    component: () => import(/* webpackChunkName: "changePassword" */ '@/view/authentication/ChangePassword.vue'),
  }
];

export default routes;
