import mutations from './mutations';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { DataService } from '../../../config/dataService/dataService';

const state = () => ({
  dashboards: {},
  calendars: {},
  user:{},
  login: Cookies.get('logedIn'),
  loading: false,
  error: null,
});

const actions = {
  async getDashboards({ commit }, {range}) {
    function formatedDate(dateString) {
        const date = dayjs(dateString);
        return date.format('YYYY-MM-DD');
    }
    try {
      commit('dashboardBegin');
      const query = await DataService.get(`dashboards?startDate=${formatedDate(range.start)}&endDate=${formatedDate(range.end)}`);
      commit('dashboardSuccess', query);
    } catch (err) {
      commit('getErr', err);
    }
  },
  async getCalendars({ commit }, {range}) {
    try {
      commit('calendarSuccess', range);
    } catch (err) {
      commit('getErr', err);
    }
  },
  async getUserProfile({ commit }) {
    try {
      commit('userBegin');
      const query = await DataService.get(`users/profile`);
      commit('userSuccess', query?.data);
      return query
    } catch (err) {
      commit('userErr', err);
    }
  },
  async getCustomerStatistics({ commit }, { range }) {
    // function formatedDate(dateString) {
    //     const date = dayjs(dateString);
    //     return date.format('YYYY-MM-DD');
    // }
    try {
      commit('customerStatisticsBegin');
      const query = await DataService.get(`dashboards/customer-statistic?date=${range.date}`);
      commit('customerStatisticsSuccess', query);
    } catch (err) {
      commit('getErr', err);
    }
  },
  async getOrderStatistics({ commit }, { range }) {
    // function formatedDate(dateString) {
    //     const date = dayjs(dateString);
    //     return date.format('YYYY-MM-DD');
    // }
    try {
      commit('orderStatisticsBegin');
      const query = await DataService.get(`dashboards/order-statistic?date=${range.date}`);
      commit('orderStatisticsSuccess', query);
    } catch (err) {
      commit('getErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
