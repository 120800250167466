export default {
  customerBegin(state) {
    state.customer = true;
  },
  customerList(state, data) {
    state.loading = false;
    state.customer = data;
  },
  customerErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  passengerBegin(state) {
    state.passenger = true;
  },
  passengerList(state, data) {
    state.loading = false;
    state.passengers = data;
  },
  passengerErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  countriesBegin(state) {
    state.country = true;
  },
  countriesList(state, data) {
    state.loading = false;
    state.country = data;
  },
  countriesErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  getBegin(state) {
    state.loading = false;
  },

  getSuccess(state, data) {
    state.loading = false;
    state.customer = data;
    state.country = data;
  },

  getErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  setPasssenger(statePassenger, data){
    statePassenger.passengers = data;
    statePassenger.loading = false
  }
};
