
export default {
    setProfitSetting(state, profitSettings) {
        state.profitSettings = profitSettings
    },
    setSuccessStatus(state, data) {
        state.successStatus = data
    },
    setHotelProfitSetting(state, hotelProfitSettings) {
        state.hotelProfitSettings = hotelProfitSettings
    },
}