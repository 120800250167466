import mutations from './mutations';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { DataService } from '../../../config/dataService/dataService';

const state = () => ({
  skypoints: {},
  detail: {},
  overview: {},
  login: Cookies.get('logedIn'),
  loading: false,
  error: null,
  pointApproveStatus: null,
  referenceDetail: {},
  pointTopUpStatus: null,
  customerData : {}
});

const actions = {
  async getPointOverview({ commit }, {range}) {
    function formatedDate(dateString) {
        const date = dayjs(dateString);
        return date.format('YYYY-MM-DD');
    }
    try {
      commit('skypointOverviewBegin');
      const query = await DataService.get(`sky-point/overview?startDate=${formatedDate(range.start)}&endDate=${formatedDate(range.end)}`);
      commit('skypointOverviewSuccess', query);
    } catch (err) {
      commit('getErr', err);
    }
  },
  // get skypoint transaction list
  async getPointTransactionList({ commit }, { pageNum, pageSize, search, type, range }) {
    try {
      commit('skypointTransactionListBegin');
      const response = await DataService.get(
        `sky-point/account-transaction?page=${pageNum}&limit=${pageSize}&search=${search}&type=${type}&startDate=${ range.start !== '' ? formatedDate(range.start) : '2020-10-20'}&endDate=${ range.end !== '' ? formatedDate(range.end) : '2024-05-23'}`
      );
      commit("skypointTransactionListSuccess", response.data);
    } catch (err) {
      console.log(err);
    }
  },
  // Point approve topup
  async approveTopup({ commit }, { id, data }) {
    try {
      const response = await DataService.post(`sky-point/top-up/approve/${id}`, data);
      commit("skypointApproveStatus", response);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  // get skypoint transaction detail
  async getPointTransactionDetail({ commit }, { stakholderUserId, type, pageNum, pageSize }) {
    try {
      commit('skypointTransactionDetailBegin');
      const response = await DataService.get(
        `sky-point/account-transaction/${stakholderUserId}?type=${type}&page=${pageNum}&limit=${pageSize}`
      );
      commit("skypointTransactionDetailSuccess", response.data);
    } catch (err) {
      console.log(err);
    }
  },
  // get skypoint reference detail
  async getPointReferenceDetail({ commit }, id) {
    try {
      commit('skypointReferenceDetailBegin');
      const query = await DataService.get(
        `sky-point/reference-code/${id}`
      );
      commit("skypointReferenceDetailSuccess", query);
    } catch (err) {
      console.log(err);
    }
  },
  // Point Toup
  async skyPointTopup({ commit }, data) {
    try {
      const response = await DataService.post(`sky-point/top-up`, data);
      commit("skypointTopUpStatus", response);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  // Fetch customer
  async fetchCustomer({ commit }, { search }) {
    try {
      commit('fetchCustomerBegin');
      const query = await DataService.get(
        `sky-point/account-list?search=${search}`
      );
      commit("fetchCustomerSuccess", query);
    } catch (err) {
      console.log(err);
    }
  },
}
function formatedDate(dateString) {
  const date = dayjs(dateString);
  return date.format('YYYY-MM-DD');
}

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
