
import { DataService } from '../../../config/dataService/dataService';
import dayjs from 'dayjs';
import mutations from './mutations';
const state = () => ({
  listCompany: [],
  companyDetail: [],
  companyAccount: [],
  filterChart: [],
  listBooking :[],
  bookingDetail: [],
  companyActivity: [],
  staffList: [],
  staffDetail: [],
  staffBookingList: [],
  staffActivity: [],
  companyDetailStatus: null,
  loading: false,
  error: null,
});

const actions = {
  async getCompanyList({ commit }, { search, status, createdSource, startDate, endDate, pageNum, pageSize }) {
    commit('setLoading', true);
    try {
      const response = await DataService.get(`/companies?query=${search}&status=${status}&createdSource=${createdSource}&startDate=${startDate}&endDate=${endDate}&pageNum=${pageNum}&pageSize=${pageSize}`);
      commit('setListCompany', response.data)
    } catch (err) {
      commit('loginErr', err);
    }
  },
  async getCompanyAccount({ commit }, { id }) {
    commit('setLoading', true);
    try {
      const response = await DataService.get(`/companies/${id}/accounts?locale=en`);
      commit('setCompanyAccount', response.data)
    } catch (err) {
      commit('loginErr', err);
    }
  },
  async getcompanyDetail({ commit }, data) {
    try {
      commit('setCompanyDetail', data);
    } catch (err) {
      commit('getErr', err);
    }
  },
  async updateStatus({ commit }, param) {
    const body = {
      status: param?.status,
      comment: param?.comment
    }
    try {
      const data = await DataService.put(`companies/${param?.id}/${param?.type}`, body);
      commit('setCompanyDetailStatus', data);
    } catch (err) {
      commit('getErr', err);
    }
  },
  async getCompanyOverview({ commit }, { id, range }) {
    function formatedDate(dateString) {
      const date = dayjs(dateString);
      return date.format('YYYY-MM-DD');
    }
    commit('setLoading', true);
    try {
      const response = await DataService.get(`/companies/${id}/overview?startDate=${formatedDate(range.start)}&endDate=${formatedDate(range.end)}`);
      commit('setCompanyOverview', response.data)
    } catch (err) {
      commit('loginErr', err);
    }
  },
  getLabel({commit}, label) {
    try{
     commit('setFilterChart', label)
    }
    catch (err){
     console.log(err)
    }
  },

  async getListBooking({ commit }, { id, startDate, endDate,search, tripType, status, pageNum, pageSize }) {
    commit('setLoading', true);
    try {
      const response = await DataService.get(`/companies/${id}/bookings?startDate=${startDate}&endDate=${endDate}&search=${search}&tripType=${tripType}&status=${status}&pageNum=${pageNum}&pageSize=${pageSize}`);
      commit('setListBooking', response.data)
    } catch (err) {
      commit('loginErr', err);
    }
  },
  async getBookingDetail({ commit }, { pnrCode }) {
    commit('setLoading', true);
    try {
      const response = await DataService.get(`/bookings/${pnrCode}`);
      commit('setBookingDetail', response.data)
    } catch (err) {
      commit('loginErr', err);
    }
  },
  async getStaffList({ commit }, { id, name }) {
    commit('setLoading', true);
    try {
      const response = await DataService.get(`/companies/${id}/staffs?query=${name}`);
      commit('setStaffList', response.data)
    } catch (err) {
      commit('loginErr', err);
    }
  },
  async getStaffDetail({ commit }, {id, staffId }) {
    commit('setLoading', true);
    try {
      const response = await DataService.get(`/companies/${id}/staffs/${staffId}`);
      commit('setStaffDetail', response.data)
    } catch (err) {
      commit('loginErr', err);
    }
  },
  async getStaffBookingList({ commit }, { id, startDate, endDate, bookingStatus, key, tripType, pageNum, pageSize, staffId }) {
    console.log('action = ', bookingStatus);
    commit('setLoading', true);
    try {
      const response = await DataService.get(`/companies/${id}/bookings?startDate=${startDate}&endDate=${endDate}&search=${key}&tripTypes=${tripType}&status=${bookingStatus}&pageNum=${pageNum}&pageSize=${pageSize}&staffId=${staffId}`);
      commit('setStaffBookingList', response.data)
    } catch (err) {
      commit('loginErr', err);
    }
  },
  async getStaffAcivity({ commit }, { id, staffId, activity, startDate, endDate }) {
    commit('setLoading', true);
    try {
      const response = await DataService.get(`/companies/${id}/staffs/${staffId}/activity-log?pageNum=&pageSize=&activity=${activity}&startDate=${startDate}&endDate=${endDate}`);
      commit('setStaffActivity', response.data)
    } catch (err) {
      commit('loginErr', err);
    }
  },
  
  async getCompanyAcivity({ commit }, { id, startDate, endDate, status, pageNum,  pageSize }) {
    commit('setLoading', true);
    try {
      const response = await DataService.get(`/companies/${id}/activity-log?startDate=${startDate}&endDate=${endDate}&activity=${status}&pageSize=${pageSize}&pageNum=${pageNum}`);
      commit('setCompanyActivity', response.data)
    } catch (err) {
      commit('loginErr', err);
    }
  },

}
 

const getters = {
  listCompany: state => state.listCompany,
  companyAccount: state => state.companyAccount,
  listBooking: state => state.listBooking,
  bookingDetail: state => state.bookingDetail,
  getLabel : state => state.filterChart,
  companyActivity: state => state.companyActivity,
  getStaffAcivity: state =>state.staffActivity
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};